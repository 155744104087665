<template>
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.96869 4.30169C6.88663 4.38375 6.77534 4.42985 6.65929 4.42985C6.54324 4.42985 6.43194 4.38375 6.34988 4.30169C6.26782 4.21963 6.22172 4.10833 6.22172 3.99228C6.22172 3.87623 6.26782 3.76493 6.34988 3.68287L9.846 0.186754C9.88663 0.146122 9.93487 0.113891 9.98796 0.0919016C10.041 0.0699118 10.0979 0.0585938 10.1554 0.0585938C10.2129 0.0585938 10.2698 0.0699118 10.3229 0.0919016C10.3759 0.113891 10.4242 0.146122 10.4648 0.186754C10.5054 0.227386 10.5377 0.275623 10.5597 0.328711C10.5817 0.381799 10.593 0.438699 10.593 0.496161C10.593 0.553623 10.5817 0.610523 10.5597 0.663611C10.5377 0.716699 10.5054 0.764936 10.4648 0.805568L6.96869 4.30169ZM1.72451 9.54587C1.68388 9.5865 1.63564 9.61873 1.58256 9.64072C1.52947 9.66271 1.47257 9.67403 1.41511 9.67403C1.35764 9.67403 1.30074 9.66271 1.24766 9.64072C1.19457 9.61873 1.14633 9.5865 1.1057 9.54587C1.06507 9.50524 1.03284 9.457 1.01085 9.40391C0.988857 9.35082 0.977539 9.29392 0.977539 9.23646C0.977539 9.179 0.988857 9.1221 1.01085 9.06901C1.03284 9.01592 1.06507 8.96769 1.1057 8.92706L4.60182 5.43094C4.68388 5.34888 4.79518 5.30278 4.91123 5.30278C5.02728 5.30278 5.13857 5.34888 5.22063 5.43094C5.30269 5.513 5.34879 5.62429 5.34879 5.74034C5.34879 5.85639 5.30269 5.96769 5.22063 6.04975L1.72451 9.54587Z"
      fill="black"
    />
    <path
      d="M1.41504 9.67286C1.29914 9.67286 1.18798 9.62682 1.10603 9.54486C1.02407 9.4629 0.978027 9.35175 0.978027 9.23584C0.978027 9.11994 1.02407 9.00878 1.10603 8.92683C1.18798 8.84487 1.29914 8.79883 1.41504 8.79883H4.91116C5.02707 8.79883 5.13822 8.84487 5.22018 8.92683C5.30214 9.00878 5.34818 9.11994 5.34818 9.23584C5.34818 9.35175 5.30214 9.4629 5.22018 9.54486C5.13822 9.62682 5.02707 9.67286 4.91116 9.67286H1.41504Z"
      fill="black"
    />
    <path
      d="M1.85206 9.23786C1.85206 9.35377 1.80601 9.46492 1.72406 9.54688C1.6421 9.62884 1.53095 9.67488 1.41504 9.67488C1.29914 9.67488 1.18798 9.62884 1.10603 9.54688C1.02407 9.46492 0.978027 9.35377 0.978027 9.23786V5.74174C0.978027 5.62584 1.02407 5.51468 1.10603 5.43273C1.18798 5.35077 1.29914 5.30473 1.41504 5.30473C1.53095 5.30473 1.6421 5.35077 1.72406 5.43273C1.80601 5.51468 1.85206 5.62584 1.85206 5.74174V9.23786ZM10.5924 3.99368C10.5924 4.10959 10.5463 4.22074 10.4644 4.3027C10.3824 4.38466 10.2712 4.4307 10.1553 4.4307C10.0394 4.4307 9.92828 4.38466 9.84633 4.3027C9.76437 4.22074 9.71833 4.10959 9.71833 3.99368V0.497562C9.71833 0.381658 9.76437 0.270502 9.84633 0.188546C9.92828 0.106589 10.0394 0.0605469 10.1553 0.0605469C10.2712 0.0605469 10.3824 0.106589 10.4644 0.188546C10.5463 0.270502 10.5924 0.381658 10.5924 0.497562V3.99368Z"
      fill="black"
    />
    <path
      d="M6.65869 0.932624C6.54279 0.932624 6.43163 0.886581 6.34968 0.804625C6.26772 0.722669 6.22168 0.611512 6.22168 0.495609C6.22168 0.379705 6.26772 0.268549 6.34968 0.186593C6.43163 0.104636 6.54279 0.0585938 6.65869 0.0585938H10.1548C10.2707 0.0585938 10.3819 0.104636 10.4638 0.186593C10.5458 0.268549 10.5918 0.379705 10.5918 0.495609C10.5918 0.611512 10.5458 0.722669 10.4638 0.804625C10.3819 0.886581 10.2707 0.932624 10.1548 0.932624H6.65869Z"
      fill="black"
    />
  </svg>
</template>
