// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorMessage = (err: any) => {
  if (err.name === "AxiosError") {
    const response = err.response?.data;
    const fieldErrors = response?.error?.field_errors;
    if (response) {
      if (fieldErrors?.length > 0) {
        const { field, message } = fieldErrors[0];

        if (message.includes(field)) {
          return message;
        } else {
          return field + message;
        }
      }

      return response?.error?.message || response?.message || err.message;
    }
    return err.message;
  }
  return "Unknown error occurred";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOtpError = (err: any) => {
  if (
    err.response?.data?.error?.code === "INVALID_OTP" ||
    err.response?.data?.error?.code === "OTP_MISSING"
  ) {
    throw err;
  }
};
