<template>
  <app-layout title="Make Payment" description="Send money to a beneficiary">
    <payments-layout>
      <div class="flex flex-wrap md:flex-nowrap gap-5 xl:gap-x-[60px]">
        <div
          class="w-full max-w-[540px] border border-greyscale-1 px-7 py-8 rounded-[10px]"
        >
          <div class="text-base font-[800] text-primary mb-4">Send From</div>

          <div v-if="isLoading" class="w-full md:w-4/5">
            <skeleton-loader :count="2" />
          </div>
          <div v-else-if="!isError && accounts" class="w-full">
            <RadioGroup v-if="accounts.length" v-model="selectedAccount">
              <RadioGroupLabel class="sr-only"
                >Choose an account</RadioGroupLabel
              >
              <div class="w-full grid grid-cols-2 xl:grid-cols-3 gap-3">
                <RadioGroupOption
                  v-for="it in accounts"
                  :key="it.id"
                  v-slot="{ checked }"
                  as="template"
                  :value="it"
                >
                  <div
                    class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer"
                    :class="
                      checked
                        ? 'bg-greyscale-1 font-[800] border-[3px] border-primary/80'
                        : 'font-medium bg-white border border-greyscale-1'
                    "
                  >
                    <div>
                      <div class="flex items-center gap-x-1 mb-2 text-xs">
                        <asset-type :asset="it.currency" no-title />
                        {{ it.currency }}
                      </div>
                      <div
                        v-if="it.balance"
                        class="text-text-secondary font-medium text-[10px] md:text-xs break-words"
                      >
                        Bal:
                        {{
                          formatAmountToMajor(it.balance.available, it.currency)
                        }}
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div v-else class="text-xs text-primary">
              You've not created any account yet
            </div>
          </div>

          <div v-else-if="isError" class="text-xs text-primary">
            Couldn't fetch your accounts - Contact support
          </div>

          <payment-disclaimer
            v-if="selectedBeneficiary?.details?.type === 'SWIFT'"
            :disclaimer="`Please ensure destination account can receive this payment in the selected currency`"
            theme="warning"
          />

          <payment-disclaimer
            v-if="paymentEligibility && !paymentEligibility.supported"
            :disclaimer="`${paymentEligibility.reason || ''}`"
            theme="fatal"
          />

          <div class="w-full flex flex-col gap-y-5 mt-8">
            <select-beneficiary-input
              :default-beneficiary="selectedBeneficiary || undefined"
              @select="handleSelectedBeneficiary"
            />

            <app-select
              v-if="
                selectedBeneficiary &&
                selectedBeneficiary.details?.type === 'SWIFT'
              "
              v-bind="formFields.currency"
              name="currency"
              label="Currency"
              :error-message="fieldErrors.currency"
              placeholder="Select currency"
              required
            >
              <option value="" disabled>Select a currency</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </app-select>

            <exchange-rate-display
              v-if="exchangeRateQuote && exchangeRateQuote.fx_rate"
              :exchange-rate="exchangeRateQuote.fx_rate"
              :source-currency="exchangeRateQuote.sending_amount.currency"
            />

            <amount-input
              v-model="amount"
              name="amount"
              label="Amount"
              :error-message="fieldErrors.amount"
              required
            />

            <app-input
              v-bind="formFields.sender_reference"
              name="sender_reference"
              label="Sender Reference"
              type="text"
              :error-message="fieldErrors.sender_reference"
              placeholder="Optional payment reference"
            />

            <app-select
              v-bind="formFields.narration"
              name="narration"
              label="Description"
              :error-message="fieldErrors.narration"
              required
            >
              <option value="" disabled>Select a description</option>
              <option
                v-for="reason in paymentNarrationList"
                :key="reason"
                :value="reason"
              >
                {{ reason }}
              </option>
            </app-select>

            <template
              v-if="
                paymentEligibility &&
                paymentEligibility.required_additional_info
              "
            >
              <template
                v-for="field in paymentEligibility.required_additional_info"
              >
                <app-input
                  v-if="field.type === 'text'"
                  :key="field.key"
                  v-model="additionalPaymentFields[field.key]"
                  :name="field.key"
                  :label="field.title"
                  type="text"
                  :pattern="field.regex || null"
                  :error-message="fieldErrors[field.key]"
                  :placeholder="field.description"
                  required
                />

                <app-select
                  v-else-if="field.type === 'select'"
                  :key="`${field.key}`"
                  v-model="additionalPaymentFields[field.key]"
                  :name="field.key"
                  :label="field.title"
                  :error-message="fieldErrors[field.key]"
                  required
                >
                  <option value="" disabled>Select a {{ field.title }}</option>

                  <template v-if="field.options">
                    <option
                      v-for="opt in field.options"
                      :key="opt.value"
                      :value="opt.value"
                    >
                      {{ opt.description }}
                    </option>
                  </template>
                </app-select>
              </template>
            </template>

            <file-input
              label="Supporting document"
              :handle-file-change="handleFileChange"
              :file-value="fileValue"
              :error-message="fieldErrors.purpose_of_payment"
              name="invoice"
            />

            <div class="w-full block md:hidden">
              <payment-summary-banner
                :transaction-fees="paymentSummary.transactionFees"
                :sending-amount="paymentSummary.sendingAmount"
                :billing-amount="paymentSummary.billingAmount"
                :receiving-amount="paymentSummary.receivingAmount"
                :exchange-rate="paymentSummary.exchangeRate"
              />
            </div>

            <app-button
              :loading="submitting"
              :disabled="submitting || !quote"
              variant="primary"
              size="lg"
              type="submit"
              @click="handleShowConfirmation"
              >Proceed</app-button
            >
          </div>
        </div>
        <div class="w-full hidden md:block max-w-[230px] lg:max-w-[300px]">
          <payment-summary-banner
            :transaction-fees="paymentSummary.transactionFees"
            :sending-amount="paymentSummary.sendingAmount"
            :billing-amount="paymentSummary.billingAmount"
            :receiving-amount="paymentSummary.receivingAmount"
            :exchange-rate="paymentSummary.exchangeRate"
          />
        </div>
      </div>

      <app-modal
        v-if="successfulTxn"
        :is-open="showTxnStatus"
        :handle-close="() => {}"
        size="lg"
      >
        <payment-success-banner
          :send-amount="successfulTxn.sendAmount"
          :fees="successfulTxn.fees"
          :narration="successfulTxn.narration"
          :beneficiary="successfulTxn.beneficiary"
          :receiving-amount="successfulTxn.receivingAmount"
          :sender-reference="successfulTxn.senderReference"
          :reference="successfulTxn.reference"
          :state="successfulTxn.state"
        />
      </app-modal>

      <app-modal
        v-else-if="txnError"
        :is-open="showTxnStatus"
        :handle-close="closeTxnStatus"
        size="lg"
      >
        <transaction-error-modal
          :close-modal="closeTxnStatus"
          :error-message="txnError"
        />
      </app-modal>

      <app-modal
        v-if="quote && selectedBeneficiary && selectedAccount"
        :is-open="showTxnConfirmation"
        :handle-close="closeTxnConfirmation"
        size="lg"
      >
        <payment-confirmation
          :close-modal="closeTxnConfirmation"
          :payment="{
            billingAmount: paymentSummary.billingAmount || {
              currency: '',
              amount: '0',
            },
            receivingAmount: paymentSummary.receivingAmount,
            beneficiary: selectedBeneficiary,
            exchangeRate: paymentSummary.exchangeRate,
            narration: values.narration,
            senderReference: values.sender_reference,
          }"
          :make-payment="handleSubmit"
        />
      </app-modal>
    </payments-layout>
  </app-layout>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, computed } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import {
  BeneficiaryResponse,
  QueryKeys,
  BankingTransactionResponse,
  AccountListResponse,
  BankingQuoteResponse,
  PaymentEligibilityResponse,
} from "@/types";
import { formatAmountToMajor, LYNC_OTP_CODE_HEADER } from "@/helpers";
import { currencyOf } from "@/helpers/currencies";
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage, isOtpError } from "@/helpers/error";
import { useField, useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useListCorporateAccounts } from "@/data-access/accounts";
import { useDefaultBeneficiary } from "@/composables/states";
import { useDefaultAccount } from "@/composables/states";
import { onBeforeRouteLeave } from "vue-router";
import { debounce } from "lodash";
import { useOtpVerification } from "@/composables/use-otp-verification";
import {
  PaymentSuccessProps,
  PaymentSummaryProps,
} from "@/components/payments/types";
import {
  getBankingPaymentSuccessProps,
  getBankingPaymentSummary,
  paymentNarrationList,
} from "@/components/payments";

interface FormFields {
  amount: number;
  narration: string;
  sender_reference?: string;
  currency?: string;
}

const { defaultAccount, updateDefaultAccount } = useDefaultAccount();
const { data, isLoading, isError } = useListCorporateAccounts();
const toast = useAppToast();
const fileValue = ref<File | null>(null);

const accounts = computed<AccountListResponse[]>(() => {
  if (data.value) {
    return data.value.filter((ac) => !ac.in_request_state).map((it) => it);
  }
  return [];
});

const showTxnStatus = ref(false);
const showTxnConfirmation = ref(false);
const successfulTxn = ref<PaymentSuccessProps>();
const txnError = ref<string>();

const additionalPaymentFields = reactive<Record<string, string>>({});

const fieldErrors = reactive<Record<string, string>>({
  amount: "",
  narration: "",
  purpose_of_payment: "",
  currency: "",
  sender_reference: "",
});

const quote = ref<BankingQuoteResponse>();
const exchangeRateQuote = ref<BankingQuoteResponse>();

const paymentEligibility = ref<PaymentEligibilityResponse | null>(null);

const selectedAccount = ref<AccountListResponse | undefined>(
  accounts.value ? accounts.value[0] : undefined,
);

const { defaultBeneficiary, updateDefaultBeneficiary } =
  useDefaultBeneficiary();
const selectedBeneficiary = ref<BeneficiaryResponse | null>(
  defaultBeneficiary.value as BeneficiaryResponse | null,
);
const loading = ref(false);

const { defineInputBinds, values } = useForm<FormFields>();

const { value: amount } = useField<number>("amount");
const formFields = reactive({
  narration: defineInputBinds("narration"),
  sender_reference: defineInputBinds("sender_reference"),
  currency: defineInputBinds("currency"),
});

const queryClient = useQueryClient();

const { execute: withOtp, reset } = useOtpVerification({
  service: "fiat",
  action: "TRANSFER",
  format: "modal",
});

const paymentSummary = computed<PaymentSummaryProps>(() => {
  return getBankingPaymentSummary(quote.value);
});

const closeTxnStatus = () => {
  showTxnStatus.value = !showTxnStatus.value;
};

const closeTxnConfirmation = () => {
  showTxnConfirmation.value = false;
};

const resetAdditionalFields = () => {
  Object.keys(additionalPaymentFields).forEach((key) => {
    additionalPaymentFields[key] = "";
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleFileChange = (e: any) => {
  const file = e.target.files[0];
  const fileSize = file.size / 1024 / 1024;
  fieldErrors.purpose_of_payment = "";

  if (fileSize > 3) {
    toast.success(
      "File greater than 3mb, Please upload another with a smaller size",
      {
        position: "top-right",
      },
    );
  } else {
    fileValue.value = file;
  }
};

const { execute: makePayment } = useWriteResource(
  bankingUrl("payments"),
  "post",
  {
    onError: (err) => {
      isOtpError(err);
      txnError.value = errorMessage(err);
      showTxnStatus.value = true;
      showTxnConfirmation.value = false;
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: checkPaymentEligibility } = useWriteResource(
  bankingUrl("payments/transfer-eligibility"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting, execute: getQuote } = useWriteResource(
  bankingUrl("quotes/transfers"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleSelectedBeneficiary = (beneficiary: BeneficiaryResponse) => {
  selectedBeneficiary.value = beneficiary;
};

const hasFieldErrors = () => {
  return Object.values(fieldErrors).some((it) => it && it.length > 0);
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }
  if (!values.narration || values.narration.length < 3) {
    fieldErrors.narration = "Description should contain at least 3 characters";
  }

  if (values.sender_reference && values.sender_reference.length > 35) {
    fieldErrors.sender_reference =
      "Sender reference should contain at most 35 characters";
  }

  if (
    paymentEligibility.value &&
    paymentEligibility.value.required_additional_info &&
    paymentEligibility.value.required_additional_info.length > 0
  ) {
    paymentEligibility.value.required_additional_info.forEach((field) => {
      if (!additionalPaymentFields[field.key]) {
        fieldErrors[field.key] = `${field.title} is required`;
      }

      if (
        field.type === "text" &&
        field.regex &&
        !new RegExp(field.regex).test(additionalPaymentFields[field.key])
      ) {
        fieldErrors[field.key] = `${field.title} is invalid`;
      }
    });
  }

  return hasFieldErrors();
};

const handleGetQuote = async () => {
  if (
    paymentEligibility.value?.supported &&
    values.amount &&
    selectedBeneficiary.value &&
    selectedAccount.value
  ) {
    const amount = Math.round(
      Number(values.amount) *
        10 ** currencyOf(selectedAccount.value?.currency || "").precision,
    );

    const res = await getQuote({
      body: {
        source_account_id: selectedAccount.value.id,
        amount: amount,
        counterparty_id: selectedBeneficiary.value.id,
        fixed_side: "destination",
        destination_currency:
          values.currency || selectedBeneficiary.value.currency,
      },
    });
    quote.value = res;

    const currency = values.currency || selectedBeneficiary.value?.currency;
    if (currency !== selectedAccount.value?.currency) {
      exchangeRateQuote.value = res;
    }
  }

  if (!selectedBeneficiary.value) {
    toast.error("Please select a beneficiary", {
      position: "top-right",
    });
  }

  if (!selectedAccount.value) {
    toast.error("Please select an account", {
      position: "top-right",
    });
    return;
  }
};

const handleGetExhangeRate = async () => {
  const currency = values.currency || selectedBeneficiary.value?.currency;

  if (
    currency !== selectedAccount.value?.currency &&
    selectedBeneficiary.value
  ) {
    const res = await getQuote({
      body: {
        source_account_id: selectedAccount.value?.id,
        amount: 100,
        counterparty_id: selectedBeneficiary.value?.id,
        fixed_side: "destination",
        destination_currency: currency,
      },
    });
    exchangeRateQuote.value = res;
  }
};

const handleShowConfirmation = () => {
  if (!isRequiredFieldsEmpty() && paymentEligibility.value?.supported) {
    showTxnConfirmation.value = true;
  }
};

const handleSubmit = async () => {
  if (quote.value) {
    const amount = Math.round(
      Number(values.amount) *
        10 ** currencyOf(selectedAccount.value?.currency || "").precision,
    );

    const body = {
      amount,
      beneficiary_id: selectedBeneficiary.value?.id,
      source_account_id: selectedAccount.value?.id,
      description: values.narration,
      sender_reference:
        values.sender_reference && values.sender_reference.length > 0
          ? values.sender_reference
          : undefined,
      destination_currency:
        values.currency || selectedBeneficiary.value?.currency,
      additional_info: Object.keys(additionalPaymentFields).map((key) => ({
        key,
        value: additionalPaymentFields[key],
      })),
    };

    try {
      loading.value = true;
      await withOtp({
        body,
        callback: async (otp?: string) => {
          const txn: BankingTransactionResponse = await makePayment({
            body,
            headers: otp ? { [LYNC_OTP_CODE_HEADER]: otp } : undefined,
          });
          loading.value = false;
          successfulTxn.value = getBankingPaymentSuccessProps(txn);
          showTxnStatus.value = true;
          showTxnConfirmation.value = false;
          queryClient.invalidateQueries({ queryKey: [QueryKeys.ACCOUNTS] });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.BANKING_TRANSACTIONS],
          });
          reset();
        },
      });
    } catch (err) {
      loading.value = false;
    }
  }
};

const handleCheckPaymentEligibility = async () => {
  if (selectedBeneficiary.value && selectedAccount.value) {
    const res = await checkPaymentEligibility({
      body: {
        counterparty_id: selectedBeneficiary.value.id,
        source_account_id: selectedAccount.value.id,
        currency: selectedBeneficiary.value.currency,
      },
    });

    paymentEligibility.value = res;

    if (res.supported) {
      handleGetExhangeRate();
    }
  }
};

watch(
  () => values.amount,
  debounce(() => {
    handleGetQuote();
    fieldErrors.amount = "";
  }, 1000),
);

watch(
  () => values.narration,
  (val) => {
    if (val.length > 3) {
      fieldErrors.narration = "";
    }
  },
);

watch(
  () => values.sender_reference,
  (val) => {
    if (val && val.length < 35) {
      fieldErrors.sender_reference = "";
    }
  },
);

watch(
  () => values.currency,
  () => {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    handleCheckPaymentEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  },
);

watch(accounts, (val) => {
  if (!defaultAccount.value && val && val.length && !selectedAccount.value) {
    selectedAccount.value = val[0];
  } else if (defaultAccount.value && val && val.length) {
    selectedAccount.value = val.filter(
      (acc) => acc.id === defaultAccount.value?.id,
    )[0];
  }
});

watch(selectedBeneficiary, (val) => {
  if (val && selectedAccount.value) {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    formFields.currency.value = val.currency;
    resetAdditionalFields();
    handleCheckPaymentEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  }
});

watch(selectedAccount, () => {
  quote.value = undefined;
  exchangeRateQuote.value = undefined;
  if (selectedBeneficiary.value) {
    handleCheckPaymentEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  }
});

watch(
  () => additionalPaymentFields,
  () => {
    if (
      paymentEligibility.value &&
      paymentEligibility.value.required_additional_info
    ) {
      paymentEligibility.value.required_additional_info.forEach((field) => {
        if (additionalPaymentFields[field.key]) {
          fieldErrors[field.key] = "";
        }
      });
    }
  },
  { deep: true },
);

onMounted(() => {
  if (defaultAccount.value && accounts.value && accounts.value.length) {
    selectedAccount.value = accounts.value.filter(
      (acc) => acc.id === defaultAccount.value?.id,
    )[0];
  }
  if (defaultBeneficiary.value) {
    selectedBeneficiary.value = defaultBeneficiary.value as BeneficiaryResponse;
    formFields.currency.value = defaultBeneficiary.value.currency;
  }
});

onBeforeRouteLeave(() => {
  updateDefaultBeneficiary(null);
  updateDefaultAccount(null);
});
</script>
