<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b-2 border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-sm text-primary font-[800]">
          Update a beneficiary details
        </h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <div class="w-full">
        <div
          v-if="countriesError || methodsError"
          class="flex gap-x-1 items-center w-full text-sm text-red-800 bg-red-100 py-2 px-6 mt-5"
        >
          <span>
            <caution-icon class="w-6 h-6 fill-red-800" />
          </span>
          <span>Error fetching available countries and payment methods</span>
        </div>
        <add-banking-beneficiary-info
          v-if="view === 'beneficiary-info'"
          :form-data="formData"
          :supported-countries="supportedCountries || []"
          :update-form-data="updateFormData"
          :close-modal="closeModal"
          :switch-form="switchForm"
        />
        <add-banking-beneficiary-address
          v-else-if="view === 'beneficiary-address'"
          :form-data="formData"
          :update-form-data="updateFormData"
          :switch-form="switchForm"
        />
        <add-banking-beneficiary-bank-address
          v-else-if="view === 'bank-address'"
          :form-data="formData"
          :update-form-data="updateFormData"
          :switch-form="switchForm"
        />
        <add-ngn-beneficiary
          v-else-if="view === 'ngn-beneficiary'"
          :form-data="formData"
          :update-form-data="updateFormData"
          :submitting="submitting"
          :handle-create="handleCreateBankingBeneficiary"
          :switch-form="switchForm"
          :banks-list="ngnBanks || []"
        />
        <add-faster-payment-beneficiary
          v-else-if="view === 'gbp-faster-payment'"
          :form-data="formData"
          :update-form-data="updateFormData"
          :submitting="submitting"
          :handle-create="handleCreateBankingBeneficiary"
          :switch-form="switchForm"
        />
        <add-banking-beneficiary-bank-form
          v-else
          :form-data="formData"
          :update-form-data="updateFormData"
          :supported-payment-methods="supportedPaymentMethods || []"
          :submitting="submitting"
          :handle-create="handleCreateBankingBeneficiary"
          :switch-form="switchForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { CreateBankingBeneficiary, BankingBeneficiaryView } from "./type";
import { BeneficiaryResponse } from "@/types";
import { useWriteResource } from "@/composables/use-resource";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { useQueryClient } from "@tanstack/vue-query";
import { QueryKeys, ServiceType } from "@/types";
import {
  useGetSupportedCountries,
  useGetSupportedPaymentMethods,
  useGetNgnBanks,
} from "@/data-access/beneficiary";

const props = defineProps<{
  closeModal: () => void;
  beneficiary: BeneficiaryResponse;
}>();

const view = ref<BankingBeneficiaryView>("beneficiary-info");
const toast = useAppToast();
const { data: supportedCountries, error: countriesError } =
  useGetSupportedCountries(ServiceType.BANKING);
const { data: supportedPaymentMethods, error: methodsError } =
  useGetSupportedPaymentMethods(ServiceType.BANKING);
const { data: ngnBanks } = useGetNgnBanks();

const queryClient = useQueryClient();

const formData = ref<CreateBankingBeneficiary>({
  country: props.beneficiary.country || "",
  beneficiary_name: props.beneficiary.name || "",
  friendly_name: props.beneficiary.friendly_name || "",
  email: props.beneficiary.email || "",
  currency: props.beneficiary.currency || "",
  kind: props.beneficiary.kind || "",
  beneficiary_address: {
    line_1: props.beneficiary.address?.line_1 || "",
    line_2: props.beneficiary.address?.line_2 || "",
    city: props.beneficiary.address?.city || "",
    state: props.beneficiary.address?.state_province || "",
    postal_code: props.beneficiary.address?.postal_code || "",
    country: props.beneficiary.address?.country || "",
  },
  method: {
    type: props.beneficiary.details.type,
    details: props.beneficiary.details.details,
  },
});

const switchForm = (params: BankingBeneficiaryView) => {
  view.value = params;
};

const updateFormData = (data: CreateBankingBeneficiary) => {
  formData.value = data;
};

const { submitting, execute } = useWriteResource(
  `v1/beneficiaries/${props.beneficiary.id}/fiat`,
  "put",
  {
    successTitle: "Beneficiary details updated",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCreateBankingBeneficiary = async (
  data: CreateBankingBeneficiary,
) => {
  await execute({
    body: {
      country: data.country,
      name: data.beneficiary_name,
      friendly_name: data.friendly_name,
      kind: data.kind,
      address: data.beneficiary_address,
      email: data.email,
      currency: data.currency,
      method: data.method,
    },
  });
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.BANKING_BENEFICIARIES],
  });
  props.closeModal();
};
</script>
