<template>
  <TooltipProvider>
    <TooltipRoot v-bind="forward">
      <TooltipTrigger as-child>
        <slot />
      </TooltipTrigger>
      <TooltipContent
        class="bg-primary text-white font-medium text-xs px-2 py-2 rounded-[5px] max-w-[250px] text-center"
        side="top"
        align="center"
      >
        {{ content }}
        <TooltipArrow :width="11" :height="5" />
      </TooltipContent>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script setup lang="ts">
import {
  TooltipProvider,
  TooltipArrow,
  TooltipContent,
  TooltipRoot,
  type TooltipRootEmits,
  type TooltipRootProps,
  TooltipTrigger,
  useForwardPropsEmits,
} from "radix-vue";

const props = defineProps<TooltipRootProps & { content?: string }>();
const emits = defineEmits<TooltipRootEmits>();

const forward = useForwardPropsEmits(props, emits);
</script>
