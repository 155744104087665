<template>
  <crypto-transactions-table
    v-if="!isError"
    :data="(transactions?.data.items as CryptoTransactionResponse[]) || []"
    :columns="columns"
    :loading="isLoading"
    :open-transaction-details="openTransactionDetails"
    :on-mobile-row-click="handleMobileRowClicked"
  />
  <error-component v-else message="Error fetching transactions" />
  <transaction-details
    v-if="showTransactionDetails && activeTransactionId"
    :id="activeTransactionId"
    :type="ServiceType.CRYPTO"
    :ext-close-modal="closeTransactionDetails"
  />
</template>

<script setup lang="ts">
import { TableColumn } from "@/components/shared/table/table.props";
import { formatDate } from "@/helpers";
import { useGetTransactions } from "@/data-access/transactions";
import { ServiceType, CryptoTransactionResponse } from "@/types";
import { ref } from "vue";

const currentPage = ref(1);
const perPage = ref(5);

const showTransactionDetails = ref(false);
const activeTransactionId = ref<string | null>(null);

const openTransactionDetails = (transactionId: string) => {
  activeTransactionId.value = transactionId;
  showTransactionDetails.value = true;
};

const closeTransactionDetails = () => {
  showTransactionDetails.value = false;
  activeTransactionId.value = null;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetTransactions(
  {
    page: currentPage,
    limit: perPage,
  },
  ServiceType.CRYPTO,
);

const columns: TableColumn<
  CryptoTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) =>
      `${row.direction.toLowerCase() === "outbound" ? "Debit" : "Credit"}`,
    dataIndex: "type",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: CryptoTransactionResponse) => {
  openTransactionDetails(row.id);
};
</script>
