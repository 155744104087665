import { formatAmount, formatAmountToMajor } from "@/helpers";
import {
  AssetsResponse,
  BankingQuoteResponse,
  BankingTransactionResponse,
  CryptoTransactionResponse,
  QuoteResponse,
} from "@/types";
import { PaymentSuccessProps, PaymentSummaryProps } from "./types";

export const getCryptoPaymentSummary = (
  quote?: QuoteResponse,
  asset?: AssetsResponse,
): PaymentSummaryProps => {
  if (quote) {
    return {
      balanceBefore: {
        currency: quote.source_amount.currency,
        amount: formatAmount(quote.balance_before),
      },
      balanceAfter: {
        currency: quote.source_amount.currency,
        amount: formatAmount(quote.balance_after),
      },
      transactionFees: {
        currency: quote.fee.currency,
        amount: formatAmount(quote.fee.amount),
      },
      sendingAmount: {
        currency: quote.source_amount.currency,
        amount: formatAmount(quote.source_amount.amount),
      },
      receivingAmount: {
        currency: quote.destination_amount.currency,
        amount: formatAmount(quote.destination_amount.amount),
      },
      exchangeRate: quote.exchange,
    };
  }
  return {
    balanceBefore: {
      currency: asset ? asset.asset.currency : "",
      amount: asset ? formatAmount(asset.balance) : "0",
    },
    balanceAfter: {
      currency: "",
      amount: "0.00",
    },
    transactionFees: {
      currency: "",
      amount: "0.00",
    },
    sendingAmount: {
      currency: asset ? asset.asset.currency : "",
      amount: "0.00",
    },
    receivingAmount: {
      currency: "",
      amount: "0.00",
    },
    exchangeRate: undefined,
  };
};

export const getBankingPaymentSummary = (
  quote?: BankingQuoteResponse,
): PaymentSummaryProps => {
  if (quote) {
    return {
      transactionFees: {
        currency: quote.total_fees.currency,
        amount: formatAmountToMajor(
          quote.total_fees.amount,
          quote.total_fees.currency,
        ),
      },
      sendingAmount: {
        currency: quote.sending_amount.currency,
        amount: formatAmountToMajor(
          quote.sending_amount.amount,
          quote.sending_amount.currency,
        ),
      },
      receivingAmount: {
        currency: quote.destination_amount.currency,
        amount: formatAmountToMajor(
          quote.destination_amount.amount,
          quote.destination_amount.currency,
        ),
      },
      billingAmount: {
        currency: quote.billing_amount.currency,
        amount: formatAmountToMajor(
          quote.billing_amount.amount,
          quote.billing_amount.currency,
        ),
      },
      exchangeRate: quote.fx_rate,
    };
  }
  return {
    transactionFees: {
      currency: "",
      amount: "0.00",
    },
    sendingAmount: {
      currency: "",
      amount: "0.00",
    },
    billingAmount: {
      currency: "",
      amount: "0.00",
    },
    receivingAmount: {
      currency: "",
      amount: "0.00",
    },
    exchangeRate: undefined,
  };
};

export const getBankingPaymentSuccessProps = (
  txn: BankingTransactionResponse,
): PaymentSuccessProps => {
  return {
    sendAmount: {
      currency: txn.sending_currency,
      amount: formatAmountToMajor(txn.sending_amount, txn.sending_currency),
    },
    fees: {
      currency: txn.total_fee_currency,
      amount: formatAmountToMajor(txn.total_fee_amount, txn.total_fee_currency),
    },
    receivingAmount: {
      currency: txn.destination_currency,
      amount: formatAmountToMajor(
        txn.destination_amount,
        txn.destination_currency,
      ),
    },
    beneficiary: `${txn.destination?.name || ""} - ${
      txn.destination?.counterparty?.details?.account_number ||
      txn.destination?.counterparty?.details?.iban
    }`,
    senderReference: txn.sender_reference,
    narration: txn.description,
    reference: txn.reference,
    state: txn.state,
  };
};

export const getCryptoPaymentSuccessProps = (
  txn: CryptoTransactionResponse,
): PaymentSuccessProps => {
  return {
    sendAmount: {
      currency: txn.source_amount.currency,
      amount: formatAmount(txn.source_amount.value),
    },
    fees: {
      currency: txn.fee.currency,
      amount: formatAmount(txn.fee.value),
    },
    narration: txn.description,
    beneficiary: txn.destination?.counterparty?.name,
    receivingAmount: {
      currency: txn.destination_amount.currency,
      amount: formatAmount(txn.destination_amount.value),
    },
    state: txn.state,
  };
};

export const paymentNarrationList = [
  "Business Payments – Payments for goods, services, supplier invoices, or business expenses.",
  "Salary & Wages – Employee salaries, consultant fees, or contractor payments.",
  "Loan Repayments – Repayment of business or personal loans.",
  "Investment & Dividends – Payments for investments, returns on investments, or dividend disbursements.",
  "Operational Expenses – Rent, utilities, and other business running costs.",
  "Refunds & Reimbursements – Reimbursement for expenses or returned payments.",
  "Grants & Donations – Charitable contributions or funding.",
  "Royalty & Licensing Fees – Payments for intellectual property use.",
  "Government Fees & Taxes – Tax payments, regulatory fees, or fines.",
  "Test Payment – Test payment to verify account.",
];
