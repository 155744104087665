export const kybRequirements = [
  {
    id: "company-information",
    title: "Company Information",
    html: `
        <div class="requirement-section">
          <p style="margin: 10px 0">All documents and information must be current and reflect verifiable information:</p>
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0"><strong>Company Name:</strong> Full legal name of the business entity</li>
            <li style="margin: 8px 0"><strong>Registration Number:</strong> Official registration or incorporation number</li>
            <li style="margin: 8px 0"><strong>Country of Incorporation:</strong> Jurisdiction where the company is registered</li>
            <li style="margin: 8px 0"><strong>Business Address:</strong> Physical and mailing address of the registered office</li>
            <li style="margin: 8px 0"><strong>Contact Information:</strong> Primary contact details, including email and phone number</li>
          </ul>
        </div>
      `,
  },
  {
    id: "business-registration-documents",
    title: "Business Registration Documents",
    html: `
        <div class="requirement-section">
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0"><strong>Certificate of Incorporation:</strong> Official document verifying the company's formation</li>
            <li style="margin: 8px 0"><strong>Memorandum & Articles of Association:</strong> Document outlining the structure, purpose, and operational rules of the company</li>
            <li style="margin: 8px 0"><strong>List of Directors:</strong> Full names, nationality, and residential addresses of all directors</li>
            <li style="margin: 8px 0"><strong>List of Ultimate Beneficial Owners (UBOs):</strong> Information on any individual with 5% or more ownership, including identification and proof of address.  
            </li>
            <li style="margin: 8px 0"><strong>TIN/EIN:</strong> Taxpayer Identification Number (TIN) or Employer Identification Number (EIN)</li>
            <li style="margin: 8px 0"><strong>Licenses or Permits:</strong> Required for companies in regulated industries, such as financial services</li>
          </ul>
        </div>
      `,
  },
  {
    id: "director-shareholder-information",
    title: "Director and Shareholder Information",
    html: `
        <div class="requirement-section">
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0">
              <strong>Director Information:</strong>
              <p style="margin: 10px 0">Identification (government-issued ID or passport) and proof of address (not older than three months) for each director. A register listing all Directors of the company and it must be signed and dated within 12 months from the time of submission.</p>
            </li>
            <li style="margin: 8px 0">
              <strong>Shareholder Register:</strong>
              <p style="margin: 10px 0">Shareholder Register accounting for 100% of the company's shares, fully allocated or not. Include names, percentage of ownership, and addresses of all shareholders. This must be dated within 12 months from the time of submission.
               <a href="https://drive.google.com/file/d/11Ws294WW5mc1CTOBJGP-cgk1-omTbb8G/view?usp=sharing" target="_blank" class="underline font-medium underline-offset-2">View a template</a>
              </p>
              
            </li>
            <li style="margin: 8px 0"><strong>Significant Shareholders:</strong> Identification and proof of address for shareholders with 5% ownership or more. <a href="https://drive.google.com/file/d/1G7pVgmmfJZj9PSJwX6bfenvqS98w8ykn/view" target="_blank" class="underline font-medium underline-offset-2">View a template</a></li>
            <li style="margin: 8px 0"><strong>Proof of Company Address:</strong> Acceptable documents include a recent utility bill, lease agreement, or other documentation showing the registered address (not older than three months) in the company's name</li>
          </ul>
  
         
        </div>
      `,
  },
  {
    id: "business-operations",
    title: "Proof of Source of Funds",
    html: `
        <div class="requirement-section">
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0"><strong>Audited Financial Statements:</strong> Typically from the last two years</li>
            <li style="margin: 8px 0"><strong>Recent Bank Statements:</strong> Statements from the last 3 to 6 months to demonstrate cash flow and operational financial health</li>
            <li style="margin: 8px 0"><strong>Equity Financing Documents:</strong> Investment documents, including share certificates and agreements with venture capitalists or other investors</li>
            <li style="margin: 8px 0"><strong>Business Sale Agreements:</strong> If funds originate from a business or asset sale, provide the sale agreement</li>
            <li style="margin: 8px 0"><strong>Dividend Payments:</strong> Documents evidencing regular dividend income</li>
            <li style="margin: 8px 0"><strong>Grants and Subsidies:</strong> Relevant government-issued documentation for grants or subsidies, applicable for startups or non-profits</li>
          </ul>
        </div>
      `,
  },
  {
    id: "purpose-of-account",
    title: "Purpose of the Account",
    html: `
        <div class="requirement-section">
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0">Description of the business operations and intended account use, such as for third-party payments, foreign exchange, or trade financing</li>
            <li style="margin: 8px 0">The Types of Products and services you provide</li>
          </ul>
        </div>
      `,
  },
  {
    id: "monthly-payment-volume",
    title: "Monthly Payment Volume",
    html: `
        <div class="requirement-section">
          <p style="margin: 10px 0">Disclosure of the expected monthly payment volume (e.g., from $50,000 to over $5,000,000)</p>
        </div>
      `,
  },
  {
    id: "kyc-aml-compliance",
    title: "KYC/AML Compliance",
    html: `
        <div class="requirement-section">
          <ul style="margin: 12px 0">
            <li style="margin: 8px 0"><strong>AML/CFT Policy:</strong> If applicable, provide an Anti-Money Laundering and Counter Financing of Terrorism policy document</li>
            <li style="margin: 8px 0"><strong>AML/CFT Compliance Attestation:</strong> Form attesting to the company's adherence to KYC and AML regulations</li>
          </ul>
        </div>
      `,
  },
  {
    id: "onboarding-questionnaire",
    title: "Onboarding Questionnaire",
    html: `
        <div class="requirement-section">
          <p style="margin: 10px 0">A fully completed Onboarding Questionnaire</p>
        </div>
      `,
  },
];
