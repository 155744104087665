import { computed } from "vue";
import { useLoggedInBusiness } from "./states";

export enum LyncFeatures {
  HELIOS = "HELIOS",
  CENTURION = "CENTURION",
  DEALS = "DEALS",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  UK_NAME_CHECKING = "UK_NAME_CHECKING",
  UPDATE_COUNTERPARTY = "UPDATE_COUNTERPARTY",
  DELETE_COUNTERPARTY = "DELETE_COUNTERPARTY",
}

export function useFeature() {
  const { business } = useLoggedInBusiness();

  const canAccess = (feature: LyncFeatures) => {
    return business.value?.features.includes(feature) || false;
  };

  const canAccessHelios = computed(() => canAccess(LyncFeatures.HELIOS));

  const canAccessCenturion = computed(() => canAccess(LyncFeatures.CENTURION));

  const canAccessDeals = computed(() => canAccess(LyncFeatures.DEALS));

  return { canAccess, canAccessHelios, canAccessCenturion, canAccessDeals };
}
