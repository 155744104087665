<template>
  <ul class="w-full flex flex-col gap-y-5 capitalize">
    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Account</div>
      <div class="text-primary text-right">
        {{ asset }}
        ({{ blockchain }})
        <router-link
          class="text-text-secondary underline"
          :to="`/accounts/crypto/${id}`"
          >View</router-link
        >
      </div>
    </li>

    <li
      v-if="address"
      class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
    >
      <div class="text-text-secondary">Address</div>
      <div
        class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
      >
        {{ address }}
        <button
          class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
          @click="copyAddress(address)"
        >
          <copy-icon /> Copy Address
        </button>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { copyToClipboard } from "@/helpers";
import { useAppToast } from "@/composables";

defineProps<{
  id: string;
  asset: string;
  blockchain: string;
  address?: string;
}>();

const toast = useAppToast();

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};
</script>
