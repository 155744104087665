<template>
  <app-table
    :data="data"
    :columns="columns"
    :loading="loading"
    :pagination="pagination"
    :header-background="headerBackground"
    @mobile-row-click="onMobileRowClick"
    @change="onTableChange || (() => {})"
  >
    <template #column-account="props">
      <div>
        <div class="flex gap-x-1">
          <asset-type
            v-if="props.row.type === 'DEPOSIT'"
            :asset="props.row.destination_currency"
          />
          <asset-type
            v-else-if="props.row.type !== 'DEPOSIT'"
            :asset="props.row.source_currency"
          />
        </div>
      </div>
    </template>

    <template #column-status="props">
      <transaction-status :status="props.row.state" />
    </template>

    <template #column-amount="props">
      <div v-if="props.row.type === 'DEPOSIT'" class="font-bold">
        {{
          `${props.row.destination_currency} ${formatAmountToMajor(
            props.row.destination_amount,
            props.row.destination_currency,
          )} `
        }}
      </div>
      <div v-else class="font-bold">
        {{
          `${props.row.source_currency} ${formatAmountToMajor(
            props.row.source_amount,
            props.row.source_currency,
          )} `
        }}
      </div>
    </template>

    <template #column-type="props">
      <div v-if="isTransactionOtc(props.row)" class="flex gap-x-1 items-center">
        <debit-icon />
        <span class="capitalize leading-[0px] text-[#B96B6B]">Otc</span>
      </div>
      <div v-else class="flex gap-x-1 items-center">
        <credit-icon v-if="props.row.type === 'DEPOSIT'" />
        <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
        <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
        <span
          class="capitalize leading-[0px]"
          :class="
            props.row.type === 'DEPOSIT'
              ? 'text-[#297FB0]'
              : props.row.type === 'TRANSFER'
                ? 'text-[#B96B6B]'
                : 'text-processing/70'
          "
          >{{ props.row.type?.toLowerCase() }}</span
        >
      </div>
    </template>

    <template #column-beneficiary="props">
      <div v-if="props.row.type === 'DEPOSIT' && props.row.source.counterparty">
        <div class="flex gap-x-2 items-start">
          <institution-icon />

          <div class="-mt-1">
            <div>
              {{
                props.row.source.counterparty.details?.accountName ||
                props.row.source.counterparty.details?.account_name ||
                "N/A"
              }}
            </div>
            <div class="text-xs text-text-primary">
              {{
                props.row.source.counterparty.details?.accountNumber ||
                props.row.source.counterparty.details?.account_number ||
                props.row.source.counterparty.details?.iban
              }}
              -
              {{
                props.row.source.counterparty.details?.bankName ||
                props.row.source.counterparty.details?.bank_name ||
                "N/A"
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          props.row.type !== 'DEPOSIT' && props.row.destination.counterparty
        "
      >
        <div class="flex gap-x-2 items-start">
          <institution-icon />

          <div class="-mt-1">
            <div v-if="props.row.destination.name">
              {{ props.row.destination.name }}
            </div>
            <div class="text-xs text-text-primary">
              {{
                props.row.destination.counterparty.details?.accountNumber ||
                props.row.destination.counterparty.details?.account_number ||
                props.row.destination.counterparty.details?.iban
              }}
              -
              {{
                props.row.destination.counterparty.details?.bankName ||
                props.row.destination.counterparty.details?.bank_name ||
                "N/A"
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          props.row.type !== 'DEPOSIT' &&
          props.row.destination.type === 'CounterParty' &&
          props.row.destination.name
        "
      >
        {{ props.row.destination.name }}
      </div>
    </template>

    <template #column-action="props">
      <button
        class="underline underline-offset-2"
        @click="openTransactionDetails(props.row.id)"
      >
        View
      </button>
    </template>

    <!-- mobile columns -->

    <template #column-mobile-account="props">
      <div class="flex justify-between items-start gap-x-3 max-w-fit">
        <div class="">
          <credit-icon v-if="props.row.type === 'DEPOSIT'" />
          <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
          <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
        </div>
        <div v-if="props.row.type === 'DEPOSIT'">
          <div class="flex gap-x-1">
            <asset-type :asset="props.row.destination_currency" />
          </div>
        </div>
        <div v-else>
          <div class="flex gap-x-1">
            <asset-type :asset="props.row.source_currency" />
          </div>
        </div>
      </div>
    </template>

    <template #column-mobile-amount="props">
      <div v-if="props.row.type === 'DEPOSIT'">
        <div class="font-bold">
          {{
            `${props.row.destination_currency} ${formatAmountToMajor(
              props.row.destination_amount,
              props.row.destination_currency,
            )}`
          }}
        </div>
        <div class="mt-1 text-xs text-right">
          {{ formatDate(props.row.created_date) }}
        </div>
      </div>
      <div v-else>
        <div class="font-bold">
          {{
            `${props.row.source_currency} ${formatAmountToMajor(
              props.row.source_amount,
              props.row.source_currency,
            )}`
          }}
        </div>
        <div class="mt-1 text-xs text-right">
          {{ formatDate(props.row.created_date) }}
        </div>
      </div>
    </template>

    <template #empty-state>
      <div
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
        <p
          v-if="!filtersAreApplied"
          class="text-base text-center text-text-primary"
        >
          Make your first payment to a beneficiary
        </p>
        <p v-else class="text-base text-center text-text-primary">
          No transactions match your filters or search query
        </p>
      </div>
    </template>
  </app-table>
</template>

<script setup lang="ts">
import { BankingTransactionResponse } from "@/types";
import { TableChangeParams, TableColumn } from "../shared/table/table.props";
import { formatAmountToMajor, formatDate } from "@/helpers";

defineProps<{
  data: BankingTransactionResponse[];
  columns: TableColumn<
    BankingTransactionResponse & {
      action: string;
    }
  >[];
  loading: boolean;
  pagination?: {
    currentPage: number;
    perPage: number;
    totalItems: number;
  };
  onMobileRowClick: (row: BankingTransactionResponse) => void;
  onTableChange?: (params: TableChangeParams) => void;
  openTransactionDetails: (transactionId: string) => void;
  filtersAreApplied?: boolean;
  headerBackground?: boolean;
}>();

const isTransactionOtc = (txn: BankingTransactionResponse): boolean => {
  return txn.tags ? txn.tags.includes("OTC") : false;
};
</script>
