export const supportedBeneficiaryCountries = [
  {
    name: "Afghanistan",
    code: "AF",
    iso3: "AFG",
    currency: "AFN",
    dial_code: "+93",
    emoji: "🇦🇫",
  },
  {
    name: "Albania",
    code: "AL",
    iso3: "ALB",
    currency: "ALL",
    dial_code: "+355",
    emoji: "🇦🇱",
  },
  {
    name: "Algeria",
    code: "DZ",
    iso3: "DZA",
    currency: "DZD",
    dial_code: "+213",
    emoji: "🇩🇿",
  },
  {
    name: "Andorra",
    code: "AD",
    iso3: "AND",
    currency: "EUR",
    dial_code: "+376",
    emoji: "🇦🇩",
  },
  {
    name: "Angola",
    code: "AO",
    iso3: "AGO",
    currency: "AOA",
    dial_code: "+244",
    emoji: "🇦🇴",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    iso3: "ATG",
    currency: "XCD",
    dial_code: "+1268",
    emoji: "🇦🇬",
  },
  {
    name: "Argentina",
    code: "AR",
    iso3: "ARG",
    currency: "ARS",
    dial_code: "+54",
    emoji: "🇦🇷",
  },
  {
    name: "Armenia",
    code: "AM",
    iso3: "ARM",
    currency: "AMD",
    dial_code: "+374",
    emoji: "🇦🇲",
  },
  {
    name: "Australia",
    code: "AU",
    iso3: "AUS",
    currency: "AUD",
    dial_code: "+61",
    emoji: "🇦🇺",
  },
  {
    name: "Austria",
    code: "AT",
    iso3: "AUT",
    currency: "EUR",
    dial_code: "+43",
    emoji: "🇦🇹",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    iso3: "AZE",
    currency: "AZN",
    dial_code: "+994",
    emoji: "🇦🇿",
  },
  {
    name: "Bahamas",
    code: "BS",
    iso3: "BHS",
    currency: "BSD",
    dial_code: "+1242",
    emoji: "🇧🇸",
  },
  {
    name: "Bahrain",
    code: "BH",
    iso3: "BHR",
    currency: "BHD",
    dial_code: "+973",
    emoji: "🇧🇭",
  },
  {
    name: "Bangladesh",
    code: "BD",
    iso3: "BGD",
    currency: "BDT",
    dial_code: "+880",
    emoji: "🇧🇩",
  },
  {
    name: "Barbados",
    code: "BB",
    iso3: "BRB",
    currency: "BBD",
    dial_code: "+1246",
    emoji: "🇧🇧",
  },
  {
    name: "Belgium",
    code: "BE",
    iso3: "BEL",
    currency: "EUR",
    dial_code: "+32",
    emoji: "🇧🇪",
  },
  {
    name: "Belize",
    code: "BZ",
    iso3: "BLZ",
    currency: "BZD",
    dial_code: "+501",
    emoji: "🇧🇿",
  },
  {
    name: "Benin",
    code: "BJ",
    iso3: "BEN",
    currency: "XOF",
    dial_code: "+229",
    emoji: "🇧🇯",
  },
  {
    name: "Bhutan",
    code: "BT",
    iso3: "BTN",
    currency: "BTN",
    dial_code: "+975",
    emoji: "🇧🇹",
  },
  {
    name: "Bolivia",
    code: "BO",
    iso3: "BOL",
    currency: "BOB",
    dial_code: "+591",
    emoji: "🇧🇴",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    iso3: "BIH",
    currency: "BAM",
    dial_code: "+387",
    emoji: "🇧🇦",
  },
  {
    name: "Botswana",
    code: "BW",
    iso3: "BWA",
    currency: "BWP",
    dial_code: "+267",
    emoji: "🇧🇼",
  },
  {
    name: "Brazil",
    code: "BR",
    iso3: "BRA",
    currency: "BRL",
    dial_code: "+55",
    emoji: "🇧🇷",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    iso3: "BRN",
    currency: "BND",
    dial_code: "+673",
    emoji: "🇧🇳",
  },
  {
    name: "Bulgaria",
    code: "BG",
    iso3: "BGR",
    currency: "BGN",
    dial_code: "+359",
    emoji: "🇧🇬",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    iso3: "BFA",
    currency: "XOF",
    dial_code: "+226",
    emoji: "🇧🇫",
  },
  {
    name: "Burundi",
    code: "BI",
    iso3: "BDI",
    currency: "BIF",
    dial_code: "+257",
    emoji: "🇧🇮",
  },
  {
    name: "Cambodia",
    code: "KH",
    iso3: "KHM",
    currency: "KHR",
    dial_code: "+855",
    emoji: "🇰🇭",
  },
  {
    name: "Cameroon",
    code: "CM",
    iso3: "CMR",
    currency: "XAF",
    dial_code: "+237",
    emoji: "🇨🇲",
  },
  {
    name: "Canada",
    code: "CA",
    iso3: "CAN",
    currency: "CAD",
    dial_code: "+1",
    emoji: "🇨🇦",
  },
  {
    name: "Cape Verde",
    code: "CV",
    iso3: "CPV",
    currency: "CVE",
    dial_code: "+238",
    emoji: "🇨🇻",
  },
  {
    name: "Central African Republic",
    code: "CF",
    iso3: "CAF",
    currency: "XAF",
    dial_code: "+236",
    emoji: "🇨🇫",
  },
  {
    name: "Chad",
    code: "TD",
    iso3: "TCD",
    currency: "XAF",
    dial_code: "+235",
    emoji: "🇹🇩",
  },
  {
    name: "Chile",
    code: "CL",
    iso3: "CHL",
    currency: "CLP",
    dial_code: "+56",
    emoji: "🇨🇱",
  },
  {
    name: "China",
    code: "CN",
    iso3: "CHN",
    currency: "CNY",
    dial_code: "+86",
    emoji: "🇨🇳",
  },
  {
    name: "Colombia",
    code: "CO",
    iso3: "COL",
    currency: "COP",
    dial_code: "+57",
    emoji: "🇨🇴",
  },
  {
    name: "Comoros",
    code: "KM",
    iso3: "COM",
    currency: "KMF",
    dial_code: "+269",
    emoji: "🇰🇲",
  },
  {
    name: "Congo",
    code: "CG",
    iso3: "COG",
    currency: "XAF",
    dial_code: "+242",
    emoji: "🇨🇬",
  },
  {
    name: "Costa Rica",
    code: "CR",
    iso3: "CRI",
    currency: "CRC",
    dial_code: "+506",
    emoji: "🇨🇷",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    iso3: "CIV",
    currency: "XOF",
    dial_code: "+225",
    emoji: "🇨🇮",
  },
  {
    name: "Croatia",
    code: "HR",
    iso3: "HRV",
    currency: "HRK",
    dial_code: "+385",
    emoji: "🇭🇷",
  },
  {
    name: "Cyprus",
    code: "CY",
    iso3: "CYP",
    currency: "EUR",
    dial_code: "+357",
    emoji: "🇨🇾",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    iso3: "CZE",
    currency: "CZK",
    dial_code: "+420",
    emoji: "🇨🇿",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    iso3: "COD",
    currency: "CDF",
    dial_code: "+243",
    emoji: "🇨🇩",
  },
  {
    name: "Denmark",
    code: "DK",
    iso3: "DNK",
    currency: "DKK",
    dial_code: "+45",
    emoji: "🇩🇰",
  },
  {
    name: "Djibouti",
    code: "DJ",
    iso3: "DJI",
    currency: "DJF",
    dial_code: "+253",
    emoji: "🇩🇯",
  },
  {
    name: "Dominica",
    code: "DM",
    iso3: "DMA",
    currency: "XCD",
    dial_code: "+1767",
    emoji: "🇩🇲",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    iso3: "DOM",
    currency: "DOP",
    dial_code: "+1849",
    emoji: "🇩🇴",
  },
  {
    name: "Ecuador",
    code: "EC",
    iso3: "ECU",
    currency: "USD",
    dial_code: "+593",
    emoji: "🇪🇨",
  },
  {
    name: "Egypt",
    code: "EG",
    iso3: "EGY",
    currency: "EGP",
    dial_code: "+20",
    emoji: "🇪🇬",
  },
  {
    name: "El Salvador",
    code: "SV",
    iso3: "SLV",
    currency: "USD",
    dial_code: "+503",
    emoji: "🇸🇻",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    iso3: "GNQ",
    currency: "XAF",
    dial_code: "+240",
    emoji: "🇬🇶",
  },
  {
    name: "Eritrea",
    code: "ER",
    iso3: "ERI",
    currency: "ERN",
    dial_code: "+291",
    emoji: "🇪🇷",
  },
  {
    name: "Estonia",
    code: "EE",
    iso3: "EST",
    currency: "EUR",
    dial_code: "+372",
    emoji: "🇪🇪",
  },
  {
    name: "Eswatini",
    code: "SZ",
    iso3: "SWZ",
    currency: "SZL",
    dial_code: "+268",
    emoji: "🇸🇿",
  },
  {
    name: "Ethiopia",
    code: "ET",
    iso3: "ETH",
    currency: "ETB",
    dial_code: "+251",
    emoji: "🇪🇹",
  },
  {
    name: "Fiji",
    code: "FJ",
    iso3: "FJI",
    currency: "FJD",
    dial_code: "+679",
    emoji: "🇫🇯",
  },
  {
    name: "Finland",
    code: "FI",
    iso3: "FIN",
    currency: "EUR",
    dial_code: "+358",
    emoji: "🇫🇮",
  },
  {
    name: "France",
    code: "FR",
    iso3: "FRA",
    currency: "EUR",
    dial_code: "+33",
    emoji: "🇫🇷",
  },
  {
    name: "Gabon",
    code: "GA",
    iso3: "GAB",
    currency: "XAF",
    dial_code: "+241",
    emoji: "🇬🇦",
  },
  {
    name: "Gambia",
    code: "GM",
    iso3: "GMB",
    currency: "GMD",
    dial_code: "+220",
    emoji: "🇬🇲",
  },
  {
    name: "Georgia",
    code: "GE",
    iso3: "GEO",
    currency: "GEL",
    dial_code: "+995",
    emoji: "🇬🇪",
  },
  {
    name: "Germany",
    code: "DE",
    iso3: "DEU",
    currency: "EUR",
    dial_code: "+49",
    emoji: "🇩🇪",
  },
  {
    name: "Ghana",
    code: "GH",
    iso3: "GHA",
    currency: "GHS",
    dial_code: "+233",
    emoji: "🇬🇭",
  },
  {
    name: "Greece",
    code: "GR",
    iso3: "GRC",
    currency: "EUR",
    dial_code: "+30",
    emoji: "🇬🇷",
  },
  {
    name: "Grenada",
    code: "GD",
    iso3: "GRD",
    currency: "XCD",
    dial_code: "+1473",
    emoji: "🇬🇩",
  },
  {
    name: "Guatemala",
    code: "GT",
    iso3: "GTM",
    currency: "GTQ",
    dial_code: "+502",
    emoji: "🇬🇹",
  },
  {
    name: "Guinea",
    code: "GN",
    iso3: "GIN",
    currency: "GNF",
    dial_code: "+224",
    emoji: "🇬🇳",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    iso3: "GNB",
    currency: "XOF",
    dial_code: "+245",
    emoji: "🇬🇼",
  },
  {
    name: "Guyana",
    code: "GY",
    iso3: "GUY",
    currency: "GYD",
    dial_code: "+595",
    emoji: "🇬🇾",
  },
  {
    name: "Haiti",
    code: "HT",
    iso3: "HTI",
    currency: "HTG",
    dial_code: "+509",
    emoji: "🇭🇹",
  },
  {
    name: "Honduras",
    code: "HN",
    iso3: "HND",
    currency: "HNL",
    dial_code: "+504",
    emoji: "🇭🇳",
  },
  {
    name: "Hong Kong",
    code: "HK",
    iso3: "HKG",
    currency: "HKD",
    dial_code: "+852",
    emoji: "🇭🇰",
  },
  {
    name: "Hungary",
    code: "HU",
    iso3: "HUN",
    currency: "HUF",
    dial_code: "+36",
    emoji: "🇭🇺",
  },
  {
    name: "Iceland",
    code: "IS",
    iso3: "ISL",
    currency: "ISK",
    dial_code: "+354",
    emoji: "🇮🇸",
  },
  {
    name: "India",
    code: "IN",
    iso3: "IND",
    currency: "INR",
    dial_code: "+91",
    emoji: "🇮🇳",
  },
  {
    name: "Indonesia",
    code: "ID",
    iso3: "IDN",
    currency: "IDR",
    dial_code: "+62",
    emoji: "🇮🇩",
  },
  {
    name: "Iraq",
    code: "IQ",
    iso3: "IRQ",
    currency: "IQD",
    dial_code: "+964",
    emoji: "🇮🇷",
  },
  {
    name: "Ireland",
    code: "IE",
    iso3: "IRL",
    currency: "EUR",
    dial_code: "+353",
    emoji: "🇮🇪",
  },
  {
    name: "Israel",
    code: "IL",
    iso3: "ISR",
    currency: "ILS",
    dial_code: "+972",
    emoji: "🇮🇱",
  },
  {
    name: "Italy",
    code: "IT",
    iso3: "ITA",
    currency: "EUR",
    dial_code: "+39",
    emoji: "🇮🇹",
  },
  {
    name: "Jamaica",
    code: "JM",
    iso3: "JAM",
    currency: "JMD",
    dial_code: "+1876",
    emoji: "🇯🇲",
  },
  {
    name: "Japan",
    code: "JP",
    iso3: "JPN",
    currency: "JPY",
    dial_code: "+81",
    emoji: "🇯🇵",
  },
  {
    name: "Jordan",
    code: "JO",
    iso3: "JOR",
    currency: "JOD",
    dial_code: "+962",
    emoji: "🇯🇴",
  },
  {
    name: "Kenya",
    code: "KE",
    iso3: "KEN",
    currency: "KES",
    dial_code: "+254",
    emoji: "🇰🇪",
  },
  {
    name: "Kiribati",
    code: "KI",
    iso3: "KIR",
    currency: "AUD",
    dial_code: "+686",
    emoji: "🇰🇮",
  },
  {
    name: "Kuwait",
    code: "KW",
    iso3: "KWT",
    currency: "KWD",
    dial_code: "+965",
    emoji: "🇰🇼",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    iso3: "KGZ",
    currency: "KGS",
    dial_code: "+996",
    emoji: "🇰🇬",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    iso3: "LAO",
    currency: "LAK",
    dial_code: "+856",
    emoji: "🇱🇦",
  },
  {
    name: "Latvia",
    code: "LV",
    iso3: "LVA",
    currency: "EUR",
    dial_code: "+371",
    emoji: "🇱🇻",
  },
  {
    name: "Lebanon",
    code: "LB",
    iso3: "LBN",
    currency: "LBP",
    dial_code: "+961",
    emoji: "🇱🇧",
  },
  {
    name: "Lesotho",
    code: "LS",
    iso3: "LSO",
    currency: "LSL",
    dial_code: "+266",
    emoji: "🇱🇸",
  },
  {
    name: "Liberia",
    code: "LR",
    iso3: "LBR",
    currency: "LRD",
    dial_code: "+231",
    emoji: "🇱🇷",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    iso3: "LIE",
    currency: "CHF",
    dial_code: "+423",
    emoji: "🇱🇮",
  },
  {
    name: "Lithuania",
    code: "LT",
    iso3: "LTU",
    currency: "EUR",
    dial_code: "+370",
    emoji: "🇱🇹",
  },
  {
    name: "Luxembourg",
    code: "LU",
    iso3: "LUX",
    currency: "EUR",
    dial_code: "+352",
    emoji: "🇱🇺",
  },
  {
    name: "Madagascar",
    code: "MG",
    iso3: "MDG",
    currency: "MGA",
    dial_code: "+261",
    emoji: "🇲🇬",
  },
  {
    name: "Malawi",
    code: "MW",
    iso3: "MWI",
    currency: "MWK",
    dial_code: "+265",
    emoji: "🇲🇼",
  },
  {
    name: "Malaysia",
    code: "MY",
    iso3: "MYS",
    currency: "MYR",
    dial_code: "+60",
    emoji: "🇲🇾",
  },
  {
    name: "Maldives",
    code: "MV",
    iso3: "MDV",
    currency: "MVR",
    dial_code: "+960",
    emoji: "🇲🇻",
  },
  {
    name: "Mali",
    code: "ML",
    iso3: "MLI",
    currency: "XOF",
    dial_code: "+223",
    emoji: "🇲🇱",
  },
  {
    name: "Malta",
    code: "MT",
    iso3: "MLT",
    currency: "EUR",
    dial_code: "+356",
    emoji: "🇲🇹",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    iso3: "MHL",
    currency: "USD",
    dial_code: "+692",
    emoji: "🇲🇭",
  },
  {
    name: "Mauritania",
    code: "MR",
    iso3: "MRT",
    currency: "MRU",
    dial_code: "+222",
    emoji: "🇲🇷",
  },
  {
    name: "Mauritius",
    code: "MU",
    iso3: "MUS",
    currency: "MUR",
    dial_code: "+230",
    emoji: "🇲🇺",
  },
  {
    name: "Mexico",
    code: "MX",
    iso3: "MEX",
    currency: "MXN",
    dial_code: "+52",
    emoji: "🇲🇽",
  },
  {
    name: "Micronesia (Federated States of)",
    code: "FM",
    iso3: "FSM",
    currency: "USD",
    dial_code: "+691",
    emoji: "🇫🇲",
  },
  {
    name: "Monaco",
    code: "MC",
    iso3: "MCO",
    currency: "EUR",
    dial_code: "+377",
    emoji: "🇲🇨",
  },
  {
    name: "Mongolia",
    code: "MN",
    iso3: "MNG",
    currency: "MNT",
    dial_code: "+976",
    emoji: "🇲🇳",
  },
  {
    name: "Montenegro",
    code: "ME",
    iso3: "MNE",
    currency: "EUR",
    dial_code: "+382",
    emoji: "🇲🇪",
  },
  {
    name: "Morocco",
    code: "MA",
    iso3: "MAR",
    currency: "MAD",
    dial_code: "+212",
    emoji: "🇲🇦",
  },
  {
    name: "Mozambique",
    code: "MZ",
    iso3: "MOZ",
    currency: "MZN",
    dial_code: "+258",
    emoji: "🇲🇿",
  },
  {
    name: "Namibia",
    code: "NA",
    iso3: "NAM",
    currency: "NAD",
    dial_code: "+264",
    emoji: "🇳🇦",
  },
  {
    name: "Nauru",
    code: "NR",
    iso3: "NRU",
    currency: "AUD",
    dial_code: "+674",
    emoji: "🇳🇷",
  },
  {
    name: "Nepal",
    code: "NP",
    iso3: "NPL",
    currency: "NPR",
    dial_code: "+977",
    emoji: "🇳🇵",
  },
  {
    name: "Netherlands",
    code: "NL",
    iso3: "NLD",
    currency: "EUR",
    dial_code: "+31",
    emoji: "🇳🇱",
  },
  {
    name: "New Zealand",
    code: "NZ",
    iso3: "NZL",
    currency: "NZD",
    dial_code: "+64",
    emoji: "🇳🇿",
  },
  {
    name: "Nicaragua",
    code: "NI",
    iso3: "NIC",
    currency: "NIO",
    dial_code: "+505",
    emoji: "🇳🇮",
  },
  {
    name: "Niger",
    code: "NE",
    iso3: "NER",
    currency: "XOF",
    dial_code: "+227",
    emoji: "🇳🇪",
  },
  {
    name: "Nigeria",
    code: "NG",
    iso3: "NGA",
    currency: "NGN",
    dial_code: "+234",
    emoji: "🇳🇬",
  },
  {
    name: "North Macedonia",
    code: "MK",
    iso3: "MKD",
    currency: "MKD",
    dial_code: "+389",
    emoji: "🇲🇰",
  },
  {
    name: "Norway",
    code: "NO",
    iso3: "NOR",
    currency: "NOK",
    dial_code: "+47",
    emoji: "🇳🇴",
  },
  {
    name: "Oman",
    code: "OM",
    iso3: "OMN",
    currency: "OMR",
    dial_code: "+968",
    emoji: "🇴🇲",
  },
  {
    name: "Pakistan",
    code: "PK",
    iso3: "PAK",
    currency: "PKR",
    dial_code: "+92",
    emoji: "🇵🇰",
  },
  {
    name: "Palau",
    code: "PW",
    iso3: "PLW",
    currency: "USD",
    dial_code: "+680",
    emoji: "🇵🇼",
  },
  {
    name: "Panama",
    code: "PA",
    iso3: "PAN",
    currency: "PAB",
    dial_code: "+507",
    emoji: "🇵🇦",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    iso3: "PNG",
    currency: "PGK",
    dial_code: "+675",
    emoji: "🇵🇬",
  },
  {
    name: "Paraguay",
    code: "PY",
    iso3: "PRY",
    currency: "PYG",
    dial_code: "+595",
    emoji: "🇵🇾",
  },
  {
    name: "Peru",
    code: "PE",
    iso3: "PER",
    currency: "PEN",
    dial_code: "+51",
    emoji: "🇵🇪",
  },
  {
    name: "Philippines",
    code: "PH",
    iso3: "PHL",
    currency: "PHP",
    dial_code: "+63",
    emoji: "🇵🇭",
  },
  {
    name: "Poland",
    code: "PL",
    iso3: "POL",
    currency: "PLN",
    dial_code: "+48",
    emoji: "🇵🇱",
  },
  {
    name: "Portugal",
    code: "PT",
    iso3: "PRT",
    currency: "EUR",
    dial_code: "+351",
    emoji: "🇵🇹",
  },
  {
    name: "Qatar",
    code: "QA",
    iso3: "QAT",
    currency: "QAR",
    dial_code: "+974",
    emoji: "🇶🇦",
  },
  {
    name: "Republic of Kosovo",
    code: "XK",
    iso3: "XKX",
    currency: "EUR",
    dial_code: "+383",
    emoji: "🇽🇰",
  },
  {
    name: "Romania",
    code: "RO",
    iso3: "ROU",
    currency: "RON",
    dial_code: "+40",
    emoji: "🇷🇴",
  },
  {
    name: "Rwanda",
    code: "RW",
    iso3: "RWA",
    currency: "RWF",
    dial_code: "+250",
    emoji: "🇷🇼",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    iso3: "KNA",
    currency: "XCD",
    dial_code: "+1869",
    emoji: "🇰🇳",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    iso3: "LCA",
    currency: "XCD",
    dial_code: "+1758",
    emoji: "🇱🇨",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    iso3: "VCT",
    currency: "XCD",
    dial_code: "+1784",
    emoji: "🇻🇨",
  },
  {
    name: "Samoa",
    code: "WS",
    iso3: "WSM",
    currency: "WST",
    dial_code: "+685",
    emoji: "🇼🇸",
  },
  {
    name: "San Marino",
    code: "SM",
    iso3: "SMR",
    currency: "EUR",
    dial_code: "+378",
    emoji: "🇸🇲",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    iso3: "STP",
    currency: "STN",
    dial_code: "+239",
    emoji: "🇸🇹",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    iso3: "SAU",
    currency: "SAR",
    dial_code: "+966",
    emoji: "🇸🇦",
  },
  {
    name: "Senegal",
    code: "SN",
    iso3: "SEN",
    currency: "XOF",
    dial_code: "+221",
    emoji: "🇸🇳",
  },
  {
    name: "Serbia",
    code: "RS",
    iso3: "SRB",
    currency: "RSD",
    dial_code: "+381",
    emoji: "🇷🇸",
  },
  {
    name: "Seychelles",
    code: "SC",
    iso3: "SYC",
    currency: "SCR",
    dial_code: "+248",
    emoji: "🇸🇨",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    iso3: "SLE",
    currency: "SLL",
    dial_code: "+232",
    emoji: "🇸🇱",
  },
  {
    name: "Singapore",
    code: "SG",
    iso3: "SGP",
    currency: "SGD",
    dial_code: "+65",
    emoji: "🇸🇬",
  },
  {
    name: "Slovakia",
    code: "SK",
    iso3: "SVK",
    currency: "EUR",
    dial_code: "+421",
    emoji: "🇸🇰",
  },
  {
    name: "Slovenia",
    code: "SI",
    iso3: "SVN",
    currency: "EUR",
    dial_code: "+386",
    emoji: "🇸🇮",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    iso3: "SLB",
    currency: "SBD",
    dial_code: "+677",
    emoji: "🇸🇧",
  },
  {
    name: "Somalia",
    code: "SO",
    iso3: "SOM",
    currency: "SOS",
    dial_code: "+252",
    emoji: "🇸🇴",
  },
  {
    name: "South Africa",
    code: "ZA",
    iso3: "ZAF",
    currency: "ZAR",
    dial_code: "+27",
    emoji: "🇿🇦",
  },
  {
    name: "South Korea",
    code: "KR",
    iso3: "KOR",
    currency: "KRW",
    dial_code: "+82",
    emoji: "🇰🇷",
  },
  {
    name: "Spain",
    code: "ES",
    iso3: "ESP",
    currency: "EUR",
    dial_code: "+34",
    emoji: "🇪🇸",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    iso3: "LKA",
    currency: "LKR",
    dial_code: "+94",
    emoji: "🇱🇰",
  },
  {
    name: "Suriname",
    code: "SR",
    iso3: "SUR",
    currency: "SRD",
    dial_code: "+597",
    emoji: "🇸🇷",
  },
  {
    name: "Sweden",
    code: "SE",
    iso3: "SWE",
    currency: "SEK",
    dial_code: "+46",
    emoji: "🇸🇪",
  },
  {
    name: "Switzerland",
    code: "CH",
    iso3: "CHE",
    currency: "CHF",
    dial_code: "+41",
    emoji: "🇨🇭",
  },
  {
    name: "Taiwan",
    code: "TW",
    iso3: "TWN",
    currency: "TWD",
    dial_code: "+886",
    emoji: "🇹🇼",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    iso3: "TJK",
    currency: "TJS",
    dial_code: "+992",
    emoji: "🇹🇯",
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    iso3: "TZA",
    currency: "TZS",
    dial_code: "+255",
    emoji: "🇹🇿",
  },
  {
    name: "Thailand",
    code: "TH",
    iso3: "THA",
    currency: "THB",
    dial_code: "+66",
    emoji: "🇹🇭",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    iso3: "TLS",
    currency: "USD",
    dial_code: "+670",
    emoji: "🇹🇱",
  },
  {
    name: "Togo",
    code: "TG",
    iso3: "TGO",
    currency: "XOF",
    dial_code: "+228",
    emoji: "🇹🇬",
  },
  {
    name: "Tonga",
    code: "TO",
    iso3: "TON",
    currency: "TOP",
    dial_code: "+676",
    emoji: "🇹🇴",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    iso3: "TTO",
    currency: "TTD",
    dial_code: "+1868",
    emoji: "🇹🇹",
  },
  {
    name: "Tunisia",
    code: "TN",
    iso3: "TUN",
    currency: "TND",
    dial_code: "+216",
    emoji: "🇹🇳",
  },
  {
    name: "Turkey",
    code: "TR",
    iso3: "TUR",
    currency: "TRY",
    dial_code: "+90",
    emoji: "🇹🇷",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    iso3: "TKM",
    currency: "TMT",
    dial_code: "+993",
    emoji: "🇹🇲",
  },
  {
    name: "Tuvalu",
    code: "TV",
    iso3: "TUV",
    currency: "AUD",
    dial_code: "+688",
    emoji: "🇹🇻",
  },
  {
    name: "Uganda",
    code: "UG",
    iso3: "UGA",
    currency: "UGX",
    dial_code: "+256",
    emoji: "🇺🇬",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    iso3: "ARE",
    currency: "AED",
    dial_code: "+971",
    emoji: "🇦🇪",
  },
  {
    name: "United Kingdom",
    code: "GB",
    iso3: "GBR",
    currency: "GBP",
    dial_code: "+44",
    emoji: "🇬🇧",
  },
  {
    name: "United States",
    code: "US",
    iso3: "USA",
    currency: "USD",
    dial_code: "+1",
    emoji: "🇺🇸",
  },
  {
    name: "Uruguay",
    code: "UY",
    iso3: "URY",
    currency: "UYU",
    dial_code: "+598",
    emoji: "🇺🇾",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    iso3: "UZB",
    currency: "UZS",
    dial_code: "+998",
    emoji: "🇺🇿",
  },
  {
    name: "Vanuatu",
    code: "VU",
    iso3: "VUT",
    currency: "VUV",
    dial_code: "+678",
    emoji: "🇻🇺",
  },
  {
    name: "Vietnam",
    code: "VN",
    iso3: "VNM",
    currency: "VND",
    dial_code: "+84",
    emoji: "🇻🇳",
  },
  {
    name: "Yemen",
    code: "YE",
    iso3: "YEM",
    currency: "YER",
    dial_code: "+967",
    emoji: "🇾🇪",
  },
  {
    name: "Zambia",
    code: "ZM",
    iso3: "ZMB",
    currency: "ZMW",
    dial_code: "+260",
    emoji: "🇿🇲",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    iso3: "ZWE",
    currency: "ZWL",
    dial_code: "+263",
    emoji: "🇿🇼",
  },
];
