<template>
  <div
    v-if="business && business.kyc"
    class="w-full flex items-center gap-x-3 bg-tertiary/5 px-5 py-4 rounded-lg mb-3"
  >
    <div class="min-w-2">
      <kyb-caution />
    </div>
    <div
      v-if="
        business?.kyc?.review_status === ReviewStatus.PENDING &&
        [
          IdentityKycVerificationState.CREATED,
          IdentityKycVerificationState.READY,
          IdentityKycVerificationState.IN_PROGRESS,
        ].includes(business.kyc.state)
      "
    >
      <p class="text-tertiary text-sm">
        <template v-if="business.business.country !== 'NG'">
          Complete your KYB to have unlimited access to Lync.
        </template>
        <template v-else>
          We can only provide <strong>NGN</strong> and stablecoin accounts to
          businesses registered in Nigeria. For <strong>USD</strong>,
          <strong>GBP</strong>, and <strong>EUR</strong> accounts, please use
          your entities registered in the US/UK/EU/CA for your KYB. Contact our
          support team at <strong>support@lync.global</strong> if you need help
          getting incorporated in the USA.
        </template>
        <span
          v-if="business?.kyc?.verification?.url"
          class="block w-fit underline font-medium underline-offset-2 cursor-pointer"
          @click="redirectToKyb(business?.kyc?.verification?.url, CustomerIoEvents.KYC_STARTED)"
        >
          Complete your KYB
      </span>
      </p>
    </div>
    <p
      v-else-if="
        business?.kyc?.review_status === ReviewStatus.PENDING &&
        business.kyc.state === IdentityKycVerificationState.IN_REVIEW
      "
      class="text-tertiary text-sm"
    >
      Your KYC is currently being reviewed. You will be notified once it is
      completed.
      <a
        v-if="business?.kyc?.verification?.url"
        :href="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2"
        target="_blank"
      >
        View your KYB
      </a>
    </p>
    <p
      v-else-if="
        business?.kyc?.review_status === ReviewStatus.CORRECTION_REQUESTED
      "
      class="text-tertiary text-sm"
    >
      A correction was requested on your KYB. Please make the necessary changes
      to continue using Lync.
      <span
        v-if="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2 cursor-pointer"
        @click="redirectToKyb(business?.kyc?.verification?.url, CustomerIoEvents.KYC_CORRECTION_STARTED)"
      >
        Complete your KYB
      </span>
    </p>
    <p
      v-else-if="business?.kyc?.review_status === ReviewStatus.REJECTED"
      class="text-tertiary text-sm"
    >
      Your KYB was rejected. Please contact support for further assistance.
      <a
        v-if="business?.kyc?.verification?.url"
        :href="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2"
        target="_blank"
      >
        View your KYB
      </a>
    </p>
    <p
      v-else-if="business?.kyc?.review_status === ReviewStatus.APPROVED"
      class="text-tertiary"
    >
      Congratulations your KYB has been approved. You now have unlimited access
      to Lync.
    </p>
  </div>
</template>

<script lang="ts" setup>
import { CustomerIoEvents } from "@/components/shared/type";
import { useLoggedInBusiness } from "@/composables/states";
import { useWriteResource } from "@/composables/use-resource";
import { IdentityKycVerificationState, ReviewStatus } from "@/types";


const { business } = useLoggedInBusiness();

const { execute: trackKycStart } = useWriteResource(
  `businesses/track`,
  "post",
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      console.log(err);
    },
  },
);

const redirectToKyb = async (url: string, event: CustomerIoEvents) => {
  await trackKycStart({
    body: {
      event,
      properties: {
        kyc_state: business.value?.kyc?.state,
        review_state: business.value?.kyc?.review_status,
      }
    },
  });
  window.open(
    url,
    "_blank",
    "noopener,noreferrer",
  );
};
</script>
