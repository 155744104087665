<template>
  <div class="relative w-full h-full bg-white overflow-y-auto">
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>

    <div class="w-full px-5 pt-5">
      <h2 class="text-lg font-bold capitalize mb-5">Exchange</h2>
    </div>

    <exchange-payment-confirmation
      v-if="showConfirmation && quote"
      :payment="getTransactionConfirmationDetails(quote)"
      :loading="loading"
      :make-payment="handleMakePayment"
      :cancel-payment="handleCancelPayment"
    >
      <template v-if="isOTPRequired && OTPComponent" #confirm-button>
        <component :is="OTPComponent" />
      </template>
    </exchange-payment-confirmation>

    <payment-success-banner
      v-else-if="successfulTxn"
      :send-amount="successfulTxn.sendAmount"
      :fees="successfulTxn.fees"
      :narration="successfulTxn.narration"
      :receiving-amount="successfulTxn.receivingAmount"
      :state="successfulTxn.state"
    />

    <div v-else-if="txnError" class="py-5">
      <transaction-error-modal
        :close-modal="closeModal"
        :error-message="txnError"
      />
    </div>

    <div v-else class="w-full px-5 py-5 flex flex-col gap-y-5">
      <div class="w-full">
        <div class="text-sm font-medium text-primary mb-3">Source Account</div>
        <div
          class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer bg-greyscale-1 font-[800]"
        >
          <div>
            <div class="flex items-center gap-x-1 mb-2 text-xs">
              <asset-type :asset="asset.asset.currency" no-title />
              {{ asset.asset.currency }} ({{ asset.asset.blockchain }})
            </div>
            <div class="text-text-secondary font-medium text-[10px] md:text-xs">
              Bal:
              {{ formatAmount(asset.balance, asset.precision) }}
            </div>
          </div>
        </div>
      </div>

      <app-select
        v-bind="formFields.destinationCurrency"
        name="destinationCurrency"
        label="Destination Account"
        :error-message="fieldErrors.destinationCurrency"
        placeholder="Select currency"
        required
      >
        <option value="" disabled>Select an account</option>
        <option
          v-for="it in swapCurrencies"
          :key="it.blockchain ? it.currency + '-' + it.blockchain : it.currency"
          :value="
            it.blockchain
              ? it.currency + '-' + it.blockchain
              : it.currency + '-'
          "
        >
          {{ it.currency + ` ${it.blockchain ? `(${it.blockchain})` : ""}` }}
          {{ it.blockchain ? "Asset" : "Account" }}
        </option>
      </app-select>

      <amount-input
        v-model="amount"
        name="amount"
        label="Amount"
        :error-message="fieldErrors.amount"
        required
      />

      <exchange-summary
        v-if="paymentSummary"
        :exchange-rate="paymentSummary.exchangeRate"
        :sending-amount="paymentSummary.sendingAmount"
        :destination-amount="paymentSummary.destinationAmount"
        :fees="paymentSummary.fees"
      />

      <app-button
        :loading="submitting"
        :disabled="submitting || !quote"
        variant="primary"
        size="lg"
        type="submit"
        @click="handleShowConfirmation"
        >Proceed</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  CryptoSwapTransactionQuoteResponse,
  QueryKeys,
  AssetsResponse,
  CryptoTransactionResponse,
  CurrentRateV2,
} from "@/types";
import { formatAmount, LYNC_OTP_CODE_HEADER } from "@/helpers";
import { reactive, watch, computed, ref } from "vue";
import { useField, useForm } from "vee-validate";
import { useWriteResource } from "@/composables/use-resource";
import { cryptoUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage, isOtpError } from "@/helpers/error";
import { useQueryClient } from "@tanstack/vue-query";
import { debounce } from "lodash";
import { useOtpVerification } from "@/composables/use-otp-verification";
import Decimal from "decimal.js";
import { PaymentSuccessProps } from "../types";
import { getCryptoPaymentSuccessProps } from "..";

const props = defineProps<{
  closeModal: () => void;
  asset: AssetsResponse;
}>();

interface FormFields {
  amount: number;
  destinationCurrency: string;
}

const fieldErrors = reactive({
  amount: "",
  destinationCurrency: "",
});

const quote = ref<CryptoSwapTransactionQuoteResponse>();
const exchangeRate = ref<CurrentRateV2 | null>(null);
const showConfirmation = ref(false);
const successfulTxn = ref<PaymentSuccessProps>();
const txnError = ref<string>();
const queryClient = useQueryClient();
const loading = ref(false);

const { defineInputBinds, values } = useForm<FormFields>();

const { value: amount } = useField<number>("amount");

const formFields = reactive({
  destinationCurrency: defineInputBinds("destinationCurrency"),
});

const toast = useAppToast();

const {
  isOTPRequired,
  execute: withOtp,
  component: OTPComponent,
  reset,
} = useOtpVerification({
  service: "crypto",
  action: "EXCHANGE",
  format: "component",
});

const swapCurrencies = computed(() => {
  return props.asset.features?.exchanges.supported || [];
});

const { submitting, execute: getQuote } = useWriteResource(
  cryptoUrl("quotes/exchange"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: makePayment } = useWriteResource(
  cryptoUrl("transactions/exchange"),
  "post",
  {
    successTitle: "Your payment is being processed",
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ASSETS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ASSET, props.asset.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ASSET_TRANSACTIONS, props.asset.id],
      });
    },
    onError: (err) => {
      isOtpError(err);
      quote.value = undefined;
      txnError.value = errorMessage(err);
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const getTransactionConfirmationDetails = (
  quote: CryptoSwapTransactionQuoteResponse,
) => {
  return {
    billing_amount: `${quote.source_amount.currency} ${formatAmount(
      quote.source_amount.amount,
    )}`,
    billing_currency: quote.source_amount.currency,
    destination_amount: `${quote.destination_amount.currency} ${formatAmount(
      quote.destination_amount.amount,
    )}`,
    exchange_rate: exchangeRate.value as CurrentRateV2,
    total_fees: `${quote.fee.currency} ${formatAmount(quote.fee.amount)}`,
  };
};

const paymentSummary = computed(() => {
  if (exchangeRate.value) {
    return {
      exchangeRate: exchangeRate.value,
      fees: {
        currency: quote.value
          ? quote.value.fee.currency
          : props.asset.asset.currency,
        amount: quote.value ? formatAmount(quote.value.fee.amount) : "0.00",
      },
      destinationAmount: {
        currency: quote.value
          ? quote.value.destination_amount.currency
          : parseCurrency(values.destinationCurrency).currency,
        amount: quote.value
          ? formatAmount(quote.value.destination_amount.amount)
          : "0.00",
      },
      sendingAmount: {
        currency: quote.value
          ? quote.value.source_amount.currency
          : props.asset.asset.currency,
        amount: quote.value
          ? new Decimal(quote.value.source_amount?.amount || 0)
              .minus(quote.value.fee?.amount || 0)
              .toString()
          : "0.00",
      },
    };
  }
  return null;
});

const handleCancelPayment = () => {
  quote.value = undefined;
  showConfirmation.value = false;
  reset();
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }

  if (!values.destinationCurrency) {
    fieldErrors.amount = "Destination currency is required";
  }

  return !values.amount || !values.destinationCurrency;
};

const handleGetQuote = async () => {
  if (!isRequiredFieldsEmpty()) {
    const res = await getQuote({
      body: {
        asset_id: props.asset.id,
        amount_in_major: values.amount.toString(),
        destination_currency: parseCurrency(values.destinationCurrency)
          .currency,
        blockchain: parseCurrency(values.destinationCurrency).blockchain,
      },
    });

    quote.value = res;
    exchangeRate.value = res.exchange;
  }
};

const handleGetExhangeRate = async () => {
  if (values.destinationCurrency) {
    const res = await getQuote({
      body: {
        asset_id: props.asset.id,
        amount_in_major: "5000.00",
        destination_currency: parseCurrency(values.destinationCurrency)
          .currency,
        blockchain: parseCurrency(values.destinationCurrency).blockchain,
      },
    });
    exchangeRate.value = res.exchange;
  }
};

const handleShowConfirmation = () => {
  if (quote.value) {
    showConfirmation.value = true;
  }
};

const handleMakePayment = async () => {
  if (quote.value) {
    const body = {
      asset_id: props.asset.id,
      amount_in_major: values.amount.toString(),
      destination_currency: parseCurrency(values.destinationCurrency).currency,
      blockchain: parseCurrency(values.destinationCurrency).blockchain,
    };

    try {
      loading.value = true;
      await withOtp({
        body,
        callback: async (otp?: string) => {
          const txn: CryptoTransactionResponse = await makePayment({
            body,
            headers: otp ? { [LYNC_OTP_CODE_HEADER]: otp } : undefined,
          });
          loading.value = false;
          reset();
          quote.value = undefined;
          successfulTxn.value = getCryptoPaymentSuccessProps(txn);
        },
      });
    } catch (err) {
      loading.value = false;
    }
  }
};

const parseCurrency = (v: string) => {
  if (!v) {
    return {
      currency: "",
      blockchain: undefined,
    };
  }
  return {
    currency: v.split("-")[0],
    blockchain: v.split("-")[1] || undefined,
  };
};

watch(
  () => values.amount,
  debounce(() => {
    if (Number(values.amount) > 0) {
      handleGetQuote();
      fieldErrors.amount = "";
    } else {
      quote.value = undefined;
    }
  }, 500),
);

watch(
  () => values.destinationCurrency,
  (val) => {
    exchangeRate.value = null;
    quote.value = undefined;
    if (val) {
      handleGetExhangeRate();
    }
    fieldErrors.destinationCurrency = "";
  },
);
</script>
