<template>
  <app-layout title="Beneficiaries" description="Manage all beneficiaries">
    <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8 mt-8">
      <TabGroup v-if="accesses.length >= 2" as="section">
        <TabList class="mt-0 px-0 flex w-full">
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-auto flex justify-center items-center py-3 h-10 px-8 focus:outline-none text-sm ${
                selected
                  ? 'text-primary font-[800] border-b-2 border-primary/60'
                  : 'text-text-primary font-medium border-b border-greyscale-1'
              } `"
            >
              Wallets
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-auto flex justify-center items-center py-3 h-10 px-8 focus:outline-none text-sm ${
                selected
                  ? 'text-primary font-[800] border-b-2 border-primary/60 '
                  : 'text-text-primary  font-medium border-b border-greyscale-1'
              } `"
            >
              Bank Accounts
            </button>
          </Tab>
        </TabList>
        <TabPanels class="pt-5">
          <TabPanel class="w-full px-0">
            <crypto-beneficiary-list />
          </TabPanel>
          <TabPanel class="w-full px-0">
            <banking-beneficiary-list />
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <div v-else-if="accesses.includes('CRYPTO')" class="w-full">
        <crypto-beneficiary-list />
      </div>
      <div v-else-if="accesses.includes('FIAT')" class="w-full">
        <banking-beneficiary-list />
      </div>
      <empty-state v-else feature="beneficiaries" />
    </section>
    <router-view></router-view>
  </app-layout>
</template>

<script lang="ts" setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useFeature } from "@/composables/use-feature";
import { computed } from "vue";

const { canAccessCenturion } = useFeature();

const accesses = computed(() => {
  let result = ["FIAT"];
  if (canAccessCenturion.value) {
    result.push("CRYPTO");
  }
  return result;
});
</script>
