<template>
  <div class="w-full bg-white rounded-[10px] overflow-hidden">
    <div class="px-6 pt-5 pb-4 bg-greyscale-1">
      <h2 class="text-xl font-bold text-primary">Confirm Your Payment</h2>
    </div>
    <div
      class="w-full flex flex-col gap-y-2 px-6 pt-2 pb-3 border-b border-greyscale-3"
    >
      <div class="py-2 border-b border-dashed border-greyscale-3">
        <div class="text-text-secondary text-sm mb-1">You will be charged</div>
        <div class="text-primary font-[800] text-lg">
          {{ payment.billingAmount.amount }}
          {{ payment.billingAmount.currency }}
        </div>
      </div>
      <div class="py-2">
        <div class="text-text-secondary text-sm mb-1">
          Your beneficiary will receive
        </div>
        <div class="text-primary font-[800] text-xl">
          {{ payment.receivingAmount.currency }}
          {{ payment.receivingAmount.amount }}
        </div>
      </div>
    </div>
    <div class="py-5 px-5 flex flex-col gap-y-5">
      <ul class="w-full flex flex-col gap-y-3">
        <data-list-item
          v-if="payment.beneficiary"
          title="Beneficiary Name"
          :value="payment.beneficiary.name || payment.beneficiary.friendly_name"
        />

        <template
          v-if="payment.beneficiary && payment.beneficiary.type === 'CRYPTO'"
        >
          <data-list-item
            v-if="payment.beneficiary.details.address"
            title="Address"
            :value="payment.beneficiary.details.address"
          />

          <data-list-item
            v-if="payment.beneficiary.details.blockchain"
            title="Blockchain"
            :value="payment.beneficiary.details.blockchain"
          />
        </template>

        <template
          v-else-if="payment.beneficiary && payment.beneficiary.type === 'FIAT'"
        >
          <data-list-item
            v-if="payment.beneficiary.country"
            title="Beneficiary Country"
            :value="countryOf(payment.beneficiary.country).name"
          />

          <data-list-item
            v-if="
              payment.beneficiary.details.details.account_number ||
              payment.beneficiary.details.details.iban
            "
            title="Account Number"
            :value="
              payment.beneficiary.details.details.account_number ||
              payment.beneficiary.details.details.iban
            "
          />

          <data-list-item
            v-if="payment.beneficiary.details.details.bank_name"
            title="Bank Name"
            :value="payment.beneficiary.details.details.bank_name"
          />
        </template>

        <data-list-item
          v-if="payment.senderReference && payment.senderReference.length"
          title="Sender Reference"
          :value="payment.senderReference"
        />

        <data-list-item
          v-if="payment.narration"
          title="Description"
          :value="payment.narration"
        />

        <data-list-item v-if="payment.exchangeRate" title="Exchange rate">
          <template #item-value>
            <rate-description
              :rate="payment.exchangeRate"
              :sending-currency="payment.billingAmount.currency"
            />
          </template>
        </data-list-item>

        <data-list-item
          v-if="payment.fees"
          title="Fees"
          :value="`${payment.fees.currency} ${payment.fees.amount}`"
        />

        <data-list-item
          v-if="payment.balanceBefore"
          title="Balance before"
          :value="`${payment.balanceBefore.currency} ${payment.balanceBefore.amount}`"
        />

        <data-list-item
          v-if="payment.balanceAfter"
          title="Balance after"
          :value="`${payment.balanceAfter.currency} ${payment.balanceAfter.amount}`"
        />
      </ul>

      <div class="flex justify-between gap-x-3 mt-5">
        <app-button variant="outlined" size="lg" @click="closeModal"
          >Cancel</app-button
        >
        <app-button
          :loading="loading"
          :disabled="loading"
          variant="primary"
          size="lg"
          @click="handleMakePayment"
          >Confirm</app-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { countryOf } from "@/helpers/countries";
import { BeneficiaryResponse, CurrentRateV2, MoneyDTO } from "@/types";
import { ref } from "vue";

interface PaymentConfirmationProps {
  billingAmount: MoneyDTO<string>;
  receivingAmount: MoneyDTO<string>;
  balanceBefore?: MoneyDTO<string>;
  balanceAfter?: MoneyDTO<string>;
  fees?: MoneyDTO<string>;
  exchangeRate?: CurrentRateV2;
  beneficiary?: BeneficiaryResponse;
  narration?: string;
  senderReference?: string;
}

const props = defineProps<{
  closeModal: () => void;
  makePayment: () => Promise<void>;
  payment: PaymentConfirmationProps;
}>();

const loading = ref(false);

const handleMakePayment = async () => {
  loading.value = true;
  await props.makePayment();
  loading.value = false;
};
</script>
