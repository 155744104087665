<template>
  <div class="w-full mt-5">
    <header class="flex justify-between items-center w-full px-5 py-4">
      <h2 class="text-primary text-base font-[800]">Transactions</h2>
      <app-button
        v-if="hasPermission(LyncPermissions.statements_read)"
        variant="primary"
        size="md"
        @click="toggleStatementModal"
      >
        <download-icon />
        Export statement
      </app-button>
    </header>
    <crypto-transactions-table
      v-if="!isError"
      :data="(transactions?.items as CryptoTransactionResponse[]) || []"
      :columns="columns"
      :loading="isLoading"
      :pagination="{
        currentPage,
        perPage,
        totalItems: transactions?.total_items || 0,
      }"
      :open-transaction-details="openTransactionDetails"
      :on-mobile-row-click="handleMobileRowClicked"
      header-background
      @change="handleTableChange"
    />
    <error-component
      v-else-if="isError"
      message="Error fetching transactions"
    />
  </div>
  <app-modal
    size="xl"
    :is-open="isStatementModalOpen"
    :handle-close="toggleStatementModal"
    align-right
  >
    <export-statement
      :close-modal="toggleStatementModal"
      :asset-id="assetId"
      :service-type="ServiceType.CRYPTO"
    />
  </app-modal>
  <transaction-details
    v-if="activeTransactionId"
    :id="activeTransactionId"
    :type="ServiceType.CRYPTO"
    :ext-close-modal="closeTransactionDetails"
  />
</template>

<script lang="ts" setup>
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { useGetAssetTransactions } from "@/data-access/assets";
import { formatDate } from "@/helpers";
import { CryptoTransactionResponse, ServiceType } from "@/types";
import { ref } from "vue";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const componentProps = defineProps<{
  assetId: string;
}>();

const currentPage = ref(1);
const perPage = ref(10);
const isStatementModalOpen = ref(false);
const activeTransactionId = ref<string | null>(null);
const { hasPermission } = usePermission();

const toggleStatementModal = () => {
  isStatementModalOpen.value = !isStatementModalOpen.value;
};

const openTransactionDetails = (id: string) => {
  activeTransactionId.value = id;
};

const closeTransactionDetails = () => {
  activeTransactionId.value = null;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetAssetTransactions(componentProps.assetId, {
  page: currentPage,
  limit: perPage,
});

const columns: TableColumn<
  CryptoTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) =>
      `${row.direction.toLowerCase() === "outbound" ? "Debit" : "Credit"}`,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Counterparty",
    selector: (row) =>
      row.destination.type === "COUNTERPARTY"
        ? row.destination.counterparty?.name.toLowerCase()
        : "N/A",
    dataIndex: "beneficiary",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: CryptoTransactionResponse) => {
  openTransactionDetails(row.id);
};

const handleTableChange = (params: TableChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};
</script>
