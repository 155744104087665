<template>
  <div class="w-full px-5 py-6">
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <app-select
        v-bind="formFields.kind"
        :error-message="errors.kind"
        label="Beneficiary type"
        name="kind"
        required
        show-required-label
      >
        <option value="" disabled selected>Select beneficiary type</option>
        <option value="INDIVIDUAL" :selected="values.kind === 'INDIVIDUAL'">
          Individual
        </option>
        <option value="BUSINESS" :selected="values.kind === 'BUSINESS'">
          Business
        </option>
      </app-select>

      <app-input
        v-bind="formFields.friendly_name"
        :error-message="errors.friendly_name"
        label="Beneficiary Nickname"
        type="text"
        name="friendly_name"
        placeholder="Enter beneficiary nickname"
      />

      <app-input
        v-bind="formFields.beneficiary_name"
        :error-message="errors.beneficiary_name"
        label="Account Holder Name"
        type="text"
        name="beneficiary_name"
        placeholder="Enter account holder name"
        required
        show-required-label
      />

      <combo-input
        label="Destination Country"
        :data="countries"
        :default-value="values.country"
        :error-message="errors.country"
        required
        show-required-label
        @select="handleSelectedCountry"
      />

      <app-select
        v-bind="formFields.currency"
        :error-message="errors.currency"
        label="Currency"
        name="currency"
        required
        show-required-label
      >
        <option value="" disabled selected>Select a currency</option>
        <option
          v-for="it in currencyList"
          :key="it.currency"
          :value="it.currency"
          :selected="values.currency === it.currency"
        >
          {{ it.currency }}
        </option>
      </app-select>
      <app-select
        v-bind="formFields.method"
        :error-message="errors.method"
        label="Payment Method"
        name="method"
        required
        show-required-label
      >
        <option value="" selected disabled>Select payment method</option>
        <option v-for="it in methodsList" :key="it" :value="it">
          {{ it === "FED_WIRE" ? "US Domestic Wire" : snakeCaseToWords(it) }}
        </option>
      </app-select>

      <app-input
        v-if="values.currency !== 'NGN' && values.method !== 'NUBAN'"
        v-bind="formFields.email"
        :error-message="errors.email"
        label="Beneficiary Email"
        type="text"
        name="email"
        placeholder="Enter business email"
        required
        show-required-label
      />

      <div class="flex justify-between gap-x-3">
        <app-button
          variant="outlined"
          size="lg"
          type="button"
          @click="closeModal"
          >Cancel</app-button
        >
        <app-button variant="primary" size="lg" type="submit">Next</app-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { computed, reactive } from "vue";
import * as yup from "yup";
import { CreateBankingBeneficiary, BankingBeneficiaryView } from "./type";
import { SupportedCountriesData } from "@/types";
import { SelectInputData } from "@/components/shared/type";
import { snakeCaseToWords } from "@/helpers";
import { supportedBeneficiaryCountries } from "@/helpers/supported-beneficiary-countries";

const props = defineProps<{
  closeModal: () => void;
  switchForm: (view: BankingBeneficiaryView) => void;
  updateFormData: (data: CreateBankingBeneficiary) => void;
  formData: CreateBankingBeneficiary;
  supportedCountries: SupportedCountriesData[];
}>();

interface FormFields {
  country: string;
  beneficiary_name: string;
  friendly_name?: string;
  method: string;
  currency: string;
  kind: string;
  email?: string;
}

const countries = computed(() => {
  return supportedBeneficiaryCountries.map((it) => ({
    label: it.name,
    value: it.code,
  }));
});

const { errors, handleSubmit, defineInputBinds, values, setFieldValue } =
  useForm<FormFields>({
    validationSchema: yup.object({
      beneficiary_name: yup.string().required().label("Beneficiary name"),
      friendly_name: yup.string().label("Friendly name"),
      email: yup
        .string()
        .email()
        .when(["currency", "method"], {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          is: (currency: any, method: any) =>
            currency === "NGN" && method === "NUBAN",
          then: (schema) => schema.notRequired(),
          otherwise: (schema) => schema.required(),
        })
        .label("Email"),
      country: yup.string().required().label("Country"),
      method: yup.string().required().label("Method"),
      kind: yup.string().required().label("kind"),
      currency: yup.string().required().label("Currency"),
    }),
    initialValues: {
      country: props.formData.country,
      beneficiary_name: props.formData.beneficiary_name,
      friendly_name: props.formData.friendly_name,
      method: props.formData.method.type,
      currency: props.formData.currency,
      kind: props.formData.kind,
      email: props.formData.email,
    },
  });

const handleSelectedCountry = (val: SelectInputData) => {
  setFieldValue("country", val.value);
};

const onSubmit = handleSubmit((formValues) => {
  props.updateFormData({
    ...props.formData,
    country: formValues.country,
    beneficiary_name: formValues.beneficiary_name,
    friendly_name: formValues.friendly_name || undefined,
    email: formValues.email || undefined,
    currency: formValues.currency,
    kind: formValues.kind,
    method: {
      type: formValues.method,
      details: props.formData.method.details,
    },
  });
  if (formValues.currency === "NGN" && formValues.method === "NUBAN") {
    props.switchForm("ngn-beneficiary");
    return;
  }
  props.switchForm("beneficiary-address");
});

const formFields = reactive({
  country: defineInputBinds("country"),
  currency: defineInputBinds("currency"),
  beneficiary_name: defineInputBinds("beneficiary_name"),
  friendly_name: defineInputBinds("friendly_name"),
  method: defineInputBinds("method"),
  email: defineInputBinds("email"),
  kind: defineInputBinds("kind"),
});

const currencyList = computed(() => {
  if (values.country && props.supportedCountries) {
    return props.supportedCountries
      .filter((item) => item.code === values.country)
      .map((item) => item.methods)[0];
  }
  return [];
});

const methodsList = computed(() => {
  if (values.currency && currencyList.value) {
    return currencyList.value
      .filter((item) => item.currency === values.currency)
      .map((item) => item.payment_methods)[0];
  }
  return [];
});
</script>
