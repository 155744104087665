<template>
  <div class="relative w-full bg-white px-5 py-12 rounded-[10px]">
    <h3 class="mb-3 text-lg font-bold text-center">Delete Beneficiary</h3>
    <p class="w-full mb-8 text-sm text-primary/70 text-center">
      This action cannot be undone. Are you sure you want to delete this
      beneficiary?
    </p>
    <div class="flex justify-between items-center gap-x-5">
      <app-button size="lg" variant="outlined" @click="closeModal">
        Cancel
      </app-button>
      <app-button
        size="lg"
        variant="danger"
        :loading="loading"
        :disabled="loading"
        @click="handleDeleteBeneficiary"
      >
        Delete
      </app-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const props = defineProps<{
  closeModal: () => void;
  deleteBeneficiary: () => void;
}>();

const loading = ref(false);

const handleDeleteBeneficiary = async () => {
  loading.value = true;
  await props.deleteBeneficiary();
  loading.value = false;
};
</script>
