import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import ToastPlugin from "vue-toast-notification";
import VueApexCharts from "vue3-apexcharts";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "vue-toast-notification/dist/theme-sugar.css";
import {
  QueryCache,
  VueQueryPlugin,
  VueQueryPluginOptions,
} from "@tanstack/vue-query";
import { useAppToast } from "./composables";
import { errorMessage } from "./helpers/error";
import { AxiosError } from "axios";

const app = createApp(App);
const toast = useAppToast();

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: true,
        retry: false,
        staleTime: 10000,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        toast.error(
          `Error fetching ${(Array.isArray(query.queryKey)
            ? query.queryKey[0]
            : "data"
          )
            .toString()
            .replace("banking-", "")
            .replace("crypto-", "")
            .replace("-", " ")}: ${errorMessage(error)}`,
          {
            duration: 4000,
          },
        );
      },
    }),
  },
};

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "api.lync.global",
    "api.waza.app",
    "api.sandbox.waza.co",
    /^\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  beforeSend(event, hint) {
    const exception = hint?.originalException;
    const error = exception as Error;

    if (
      error?.message?.includes("401") ||
      (error as AxiosError)?.response?.status === 401 ||
      event?.exception?.values?.[0]?.value?.includes("401")
    ) {
      const url = (error as AxiosError)?.config?.url || event?.request?.url;

      if (url?.includes("/businesses/me")) {
        return null;
      }
    }

    return event;
  },
});

app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(VueApexCharts);
app.use(ToastPlugin);
app.use(router);
app.mount("#app");
