<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95833 15.0413H5.06667L11.8948 8.21322L10.7865 7.10488L3.95833 13.933V15.0413ZM15.2792 7.0653L11.9146 3.7403L13.0229 2.63197C13.3264 2.32849 13.6993 2.17676 14.1415 2.17676C14.5833 2.17676 14.9559 2.32849 15.2594 2.63197L16.3677 3.7403C16.6712 4.04377 16.8295 4.41005 16.8427 4.83913C16.8559 5.26769 16.7108 5.6337 16.4073 5.93717L15.2792 7.0653ZM3.16667 16.6247C2.94236 16.6247 2.75447 16.5487 2.603 16.3967C2.451 16.2452 2.375 16.0573 2.375 15.833V13.5965C2.375 13.491 2.39479 13.3889 2.43438 13.2902C2.47396 13.191 2.53333 13.1018 2.6125 13.0226L10.7667 4.86842L14.1313 8.23301L5.97708 16.3872C5.89792 16.4663 5.80899 16.5257 5.71029 16.5653C5.61107 16.6049 5.50868 16.6247 5.40312 16.6247H3.16667ZM11.3406 7.65905L10.7865 7.10488L11.8948 8.21322L11.3406 7.65905Z"
      fill="#05051E"
      fill-opacity="0.6"
    />
  </svg>
</template>
