<template>
  <section
    class="relative w-full xl:w-[35%] border border-greyscale-1 rounded-[10px]"
  >
    <div
      class="px-6 py-4 border-b border-greyscale-1 flex justify-between items-center"
    >
      <h2 class="text-primary text-sm font-[800] flex items-center gap-x-2">
        KYB knowledge base

        <app-tooltip
          content="This guide explains all requirements to complete KYB. We require business documents to know your business and understand the nature of your business."
        >
          <div class="cursor-pointer">
            <kyb-caution class="w-4 h-4" />
          </div>
        </app-tooltip>
      </h2>
      <button
        class="text-sm text-primary flex items-center"
        @click="showRequirementsList"
      >
        <span>View all </span>
        <arrow-right-icon />
      </button>
    </div>

    <ul class="px-5 py-5 flex flex-col gap-y-4 overflow-y-auto h-[350px]">
      <li
        v-for="item in kybRequirements"
        :key="item.id"
        class="w-full border border-[#E8EAE2] rounded-[5px] px-5 py-3 text-sm text-primary"
        @click="openRequirementModal(item)"
      >
        <button class="w-full flex justify-between gap-x-2 items-center">
          <span class="text-left">
            {{ item.title }}
          </span>

          <span
            class="bg-[#F9F9F9] rounded-full w-6 h-6 flex justify-center items-center"
          >
            <expand-icon />
          </span>
        </button>
      </li>
    </ul>
  </section>
  <app-modal
    v-if="activeRequirement"
    :is-open="isRequirementDetailsModalOpen"
    :handle-close="closeRequirementModal"
    size="lg"
  >
    <requirement-details-modal
      :requirement="activeRequirement"
      :close-modal="closeRequirementModal"
    />
  </app-modal>

  <app-modal
    :is-open="isRequirementListModalOpen"
    :handle-close="closeRequirementsList"
    size="xl"
  >
    <kyc-requirements-modal
      :requirements="kybRequirements"
      :close-modal="closeRequirementsList"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { kybRequirements } from "./constants";

type TRequirement = {
  id: string;
  title: string;
  html: string;
};

const isRequirementDetailsModalOpen = ref(false);
const isRequirementListModalOpen = ref(false);

const activeRequirement = ref<TRequirement | null>(null);

const openRequirementModal = (requirement: TRequirement) => {
  activeRequirement.value = requirement;
  isRequirementDetailsModalOpen.value = true;
};

const closeRequirementModal = () => {
  isRequirementDetailsModalOpen.value = false;
  activeRequirement.value = null;
};

const showRequirementsList = () => {
  closeRequirementModal();
  isRequirementListModalOpen.value = true;
};

const closeRequirementsList = () => {
  isRequirementListModalOpen.value = false;
};
</script>
