<template>
  <header
    class="flex flex-wrap md:flex-nowrap justify-between gap-x-8 gap-y-3 mb-8"
  >
    <input
      v-model="benficiaryNameQueryModel"
      class="w-full h-10 border border-greyscale-7 text-sm text-primary rounded-[5px] focus:outline-primary placeholder:text-sm px-4 py-1 placeholder:text-primary-primary bg-gray-50"
      placeholder="Search beneficiaries by name"
      type="text"
    />
    <div
      class="w-auto md:min-w-max flex flex-wrap md:justify-end gap-x-3 gap-y-3"
    >
      <!-- <filter-menu :options="[]" :handle-change="() => {}" /> -->

      <app-button
        v-if="hasPermission(LyncPermissions.counterparties_write)"
        variant="primary"
        size="md"
        @click="openAddBeneficiaryModal"
      >
        <span class="w-[13px] h-[14px] inline-block"><plus-icon /></span>
        <span class="hidden md:block">New beneficiary</span>
        <span class="md:hidden">Create</span>
      </app-button>
    </div>
  </header>
  <app-table
    v-if="!isError"
    :data="beneficiaries?.items || []"
    :columns="columns"
    :loading="isLoading"
    :pagination="{
      currentPage,
      perPage,
      totalItems: beneficiaries?.total_items || 0,
    }"
    @mobile-row-click="handleMobileRowClicked"
    @change="handleTableChange"
  >
    <template #column-name="props">
      <div class="flex gap-x-2 capitalize">
        <user-icon /> {{ props.row.name.toLowerCase() }}
      </div>
    </template>
    <template #column-account="props">
      <div class="flex gap-x-2 items-center">
        <wallet-icon />
        <span class="break-all">
          {{ maskAddress(props.row.details.address) }}</span
        >
      </div>
    </template>
    <template #column-type="props">
      <div class="flex gap-x-1 items-center text-xs md:text-sm">
        <asset-type :asset="props.row.currency" />
        <span>({{ props.row.details.blockchain }})</span>
      </div>
    </template>

    <template #column-action="props">
      <router-link
        class="underline underline-offset-2"
        :to="`/beneficiaries/${props.row.id}?type=crypto`"
      >
        View
      </router-link>
    </template>
    <template #empty-state>
      <div
        v-if="benficiaryNameQuery.length"
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">No beneficiaries found</h4>
        <p class="text-base text-center text-text-primary">
          No beneficiary matches your search query.
        </p>
      </div>
      <div
        v-else
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">
          Add your first beneficiary
        </h4>
        <p class="text-base text-center text-text-primary">
          No beneficiaries have been added to this account yet.
        </p>
        <app-button
          v-if="hasPermission(LyncPermissions.counterparties_write)"
          variant="primary"
          size="md"
          @click="openAddBeneficiaryModal"
          >Add beneficiary</app-button
        >
      </div>
    </template>
  </app-table>
  <error-component v-else-if="isError" message="Error fetching beneficiaries" />
  <app-modal
    :is-open="isAddModalOpen"
    :handle-close="closeAddBeneficiaryModal"
    size="lg"
  >
    <create-crypto-beneficiary :close-modal="closeAddBeneficiaryModal" />
  </app-modal>
</template>

<script setup lang="ts">
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { useGetBeneficiaries } from "@/data-access/beneficiary";
import { maskAddress } from "@/helpers";
import { BeneficiaryResponse } from "@/types";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { debounce } from "lodash";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const isAddModalOpen = ref(false);
const benficiaryNameQueryModel = ref("");
const benficiaryNameQuery = ref("");

const currentPage = ref(1);
const perPage = ref(10);
const route = useRoute();

const { hasPermission } = usePermission();

const newBeneficiaryQuery = route.query["add-new"];
const router = useRouter();
const {
  data: beneficiaries,
  isLoading,
  isError,
} = useGetBeneficiaries({
  page: currentPage,
  limit: perPage,
  filters: {
    keyword: benficiaryNameQuery,
    type: ref("CRYPTO"),
  },
});

const openAddBeneficiaryModal = () => {
  isAddModalOpen.value = true;
};

const closeAddBeneficiaryModal = () => {
  isAddModalOpen.value = false;
};

watch(
  benficiaryNameQueryModel,
  debounce((val) => {
    if (val.length >= 4) {
      benficiaryNameQuery.value = val;
    }
    if (val.length === 0) {
      benficiaryNameQuery.value = "";
    }
  }, 500),
);

const columns: TableColumn<
  BeneficiaryResponse & {
    action: string;
  }
>[] = [
  {
    label: "Recipient Name",
    selector: () => {},
    dataIndex: "name",
    showOnMobile: false,
  },
  {
    label: "Account Number / Address",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) => row.type,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "name",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "type",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: BeneficiaryResponse) => {
  router.push(`/beneficiaries/${row.id}`);
};

const handleTableChange = (params: TableChangeParams) => {
  (perPage.value = params.perPage), (currentPage.value = params.currentPage);
};

onMounted(() => {
  if (newBeneficiaryQuery) {
    setTimeout(() => {
      openAddBeneficiaryModal();
    }, 500);
  }
});
</script>
