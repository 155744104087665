<template>
  <app-layout
    title="Send stablecoin"
    description="Send stablecoin to a beneficiary"
  >
    <payments-layout>
      <div class="flex flex-wrap md:flex-nowrap gap-5 xl:gap-x-[60px]">
        <div
          class="w-full max-w-[540px] border border-greyscale-1 px-7 py-8 rounded-[10px]"
        >
          <div class="text-base font-[800] text-primary mb-4">Send From</div>

          <div v-if="isLoading" class="w-full md:w-4/5">
            <skeleton-loader :count="2" />
          </div>
          <div v-else-if="assets && assets.items" class="w-full">
            <RadioGroup v-if="assets.items.length" v-model="selectedAsset">
              <RadioGroupLabel class="sr-only">Choose an asset</RadioGroupLabel>
              <div class="w-full grid grid-cols-2 xl:grid-cols-3 gap-3">
                <RadioGroupOption
                  v-for="it in assets.items"
                  :key="it.id"
                  v-slot="{ checked }"
                  as="template"
                  :value="it"
                >
                  <div
                    class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer"
                    :class="
                      checked
                        ? 'bg-greyscale-1 font-[800] border-[3px] border-primary/80'
                        : 'font-medium bg-white border border-greyscale-1'
                    "
                  >
                    <div>
                      <div class="flex items-center gap-x-1 mb-2 text-xs">
                        <asset-type :asset="it.asset.currency" no-title />
                        {{ it.asset.currency }}
                        <span
                          v-if="it.asset?.blockchain"
                          class="capitalize truncate"
                          >({{ it.asset.blockchain.toLowerCase() }})</span
                        >
                      </div>
                      <div
                        class="text-text-secondary font-medium text-[10px] md:text-xs break-words"
                      >
                        Bal: {{ formatAmount(it.balance) }}
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div v-else class="text-xs text-primary">
              You've not created any assets yet
            </div>
          </div>

          <div v-else-if="isError" class="text-xs text-primary">
            Couldn't fetch your assets - Contact support
          </div>

          <payment-disclaimer
            v-if="transferEligibility && !transferEligibility.supported"
            :disclaimer="`${transferEligibility.reason || ''}`"
            theme="fatal"
          />

          <div class="w-full flex flex-col gap-y-5 mt-8">
            <select-beneficiary-input
              :default-beneficiary="selectedBeneficiary || undefined"
              @select="handleSelectedBeneficiary"
            />

            <app-select
              v-if="
                selectedBeneficiary &&
                selectedBeneficiary.details?.type === 'SWIFT'
              "
              v-bind="formFields.currency"
              name="currency"
              label="Currency"
              :error-message="fieldErrors.currency"
              placeholder="Select currency"
              required
            >
              <option value="" disabled>Select a currency</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </app-select>

            <exchange-rate-display
              v-if="exchangeRateQuote && exchangeRateQuote.exchange"
              :exchange-rate="exchangeRateQuote.exchange"
              :source-currency="exchangeRateQuote.source_amount.currency"
            />

            <amount-input
              v-model="amount"
              name="amount"
              label="Amount"
              :error-message="fieldErrors.amount"
              required
            />

            <app-input
              v-bind="formFields.narration"
              name="narration"
              label="Narration"
              type="text"
              :error-message="fieldErrors.narration"
              placeholder="Enter purpose of payment"
              required
            />

            <file-input
              label="Supporting document"
              :handle-file-change="handleFileChange"
              :file-value="fileValue"
              :error-message="fieldErrors.purpose_of_payment"
              name="invoice"
            />

            <div class="w-full block md:hidden">
              <payment-summary-banner
                :balance-before="paymentSummary.balanceBefore"
                :balance-after="paymentSummary.balanceAfter"
                :transaction-fees="paymentSummary.transactionFees"
                :sending-amount="paymentSummary.sendingAmount"
                :receiving-amount="paymentSummary.receivingAmount"
                :exchange-rate="paymentSummary.exchangeRate"
              />
            </div>

            <app-button
              :loading="submitting"
              :disabled="submitting || !quote"
              variant="primary"
              size="lg"
              type="submit"
              @click="handleShowConfirmation"
              >Proceed</app-button
            >
          </div>
        </div>

        <div class="w-full hidden md:block max-w-[230px] lg:max-w-[300px]">
          <payment-summary-banner
            :balance-before="paymentSummary.balanceBefore"
            :balance-after="paymentSummary.balanceAfter"
            :transaction-fees="paymentSummary.transactionFees"
            :sending-amount="paymentSummary.sendingAmount"
            :receiving-amount="paymentSummary.receivingAmount"
            :exchange-rate="paymentSummary.exchangeRate"
          />
        </div>
      </div>

      <app-modal
        v-if="successfulTxn"
        :is-open="showTxnStatus"
        :handle-close="() => {}"
        size="lg"
      >
        <payment-success-banner
          :send-amount="successfulTxn.sendAmount"
          :fees="successfulTxn.fees"
          :narration="successfulTxn.narration"
          :beneficiary="successfulTxn.beneficiary"
          :receiving-amount="successfulTxn.receivingAmount"
          :state="successfulTxn.state"
        />
      </app-modal>

      <app-modal
        v-else-if="txnError"
        :is-open="showTxnStatus"
        :handle-close="closeTxnStatus"
        size="lg"
      >
        <transaction-error-modal
          :close-modal="closeTxnStatus"
          :error-message="txnError"
        />
      </app-modal>

      <app-modal
        v-if="quote && selectedBeneficiary && selectedAsset"
        :is-open="showTxnConfirmation"
        :handle-close="closeTxnConfirmation"
        size="lg"
      >
        <payment-confirmation
          :close-modal="closeTxnConfirmation"
          :payment="{
            billingAmount: paymentSummary.sendingAmount,
            receivingAmount: paymentSummary.receivingAmount,
            beneficiary: selectedBeneficiary,
            exchangeRate: paymentSummary.exchangeRate,
            narration: values.narration,
          }"
          :beneficiary="selectedBeneficiary"
          :make-payment="handleSubmit"
        />
      </app-modal>
    </payments-layout>
  </app-layout>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, computed } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { useGetAssets } from "@/data-access/assets";
import {
  AssetsResponse,
  BeneficiaryResponse,
  QueryKeys,
  QuoteResponse,
  CryptoTransactionResponse,
  PaymentEligibilityResponse,
} from "@/types";
import { formatAmount, LYNC_OTP_CODE_HEADER } from "@/helpers";
import { useWriteResource } from "@/composables/use-resource";
import { cryptoUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage, isOtpError } from "@/helpers/error";
import { useField, useForm } from "vee-validate";
import { useQueryClient } from "@tanstack/vue-query";
import { useDefaultAsset, useDefaultBeneficiary } from "@/composables/states";
import { onBeforeRouteLeave } from "vue-router";
import { debounce } from "lodash";
import { useOtpVerification } from "@/composables/use-otp-verification";
import {
  PaymentSuccessProps,
  PaymentSummaryProps,
} from "@/components/payments/types";
import {
  getCryptoPaymentSuccessProps,
  getCryptoPaymentSummary,
} from "@/components/payments";

interface FormFields {
  amount: string;
  narration: string;
  currency?: string;
}

const { data: assets, isLoading, isError } = useGetAssets();
const toast = useAppToast();
const fileValue = ref<File | null>(null);

const showTxnStatus = ref(false);
const showTxnConfirmation = ref(false);
const successfulTxn = ref<PaymentSuccessProps>();
const txnError = ref<string>();
const fieldErrors = reactive({
  amount: "",
  narration: "",
  purpose_of_payment: "",
  currency: "",
});

const { defaultBeneficiary, updateDefaultBeneficiary } =
  useDefaultBeneficiary();
const { defaultAsset, updateDefaultAsset } = useDefaultAsset();

const selectedAsset = ref<AssetsResponse | undefined>(assets.value?.items[0]);
const selectedBeneficiary = ref<BeneficiaryResponse | null>(
  defaultBeneficiary.value as BeneficiaryResponse | null,
);
const quote = ref<QuoteResponse>();
const exchangeRateQuote = ref<QuoteResponse>();

const transferEligibility = ref<PaymentEligibilityResponse | null>(null);
const loading = ref(false);

const { defineInputBinds, values } = useForm<FormFields>();
const { value: amount } = useField<number>("amount");

const formFields = reactive({
  narration: defineInputBinds("narration"),
  currency: defineInputBinds("currency"),
});

const queryClient = useQueryClient();

const paymentSummary = computed<PaymentSummaryProps>(() => {
  return getCryptoPaymentSummary(quote.value, selectedAsset.value);
});

const { execute: withOtp, reset } = useOtpVerification({
  service: "crypto",
  action: "TRANSFER",
  format: "modal",
});

const closeTxnStatus = () => {
  showTxnStatus.value = !showTxnStatus.value;
};

const closeTxnConfirmation = () => {
  showTxnConfirmation.value = false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleFileChange = (e: any) => {
  const file = e.target.files[0];
  const fileSize = file.size / 1024 / 1024;
  fieldErrors.purpose_of_payment = "";

  if (fileSize > 3) {
    toast.success(
      "File greater than 3mb, Please upload another with a smaller size",
      {
        position: "top-right",
      },
    );
  } else {
    fileValue.value = file;
  }
};

const { submitting, execute: getQuote } = useWriteResource(
  cryptoUrl("/quotes/transfers"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: makePayment } = useWriteResource(
  cryptoUrl("/transactions/withdrawal"),
  "post",
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      isOtpError(err);
      txnError.value = errorMessage(err);
      showTxnStatus.value = true;
      showTxnConfirmation.value = false;
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: checkTransferEligibility } = useWriteResource(
  cryptoUrl("transactions/transfer-eligibility"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleSelectedBeneficiary = (beneficiary: BeneficiaryResponse) => {
  selectedBeneficiary.value = beneficiary;
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }
  if (!values.narration || values.narration.length < 3) {
    fieldErrors.narration = "Description should contain at least 3 characters";
  }

  if (selectedBeneficiary.value && selectedBeneficiary.value.type === "FIAT") {
    if (!fileValue.value) {
      fieldErrors.purpose_of_payment = "Supporting document is required";
    }

    return (
      !values.amount ||
      !values.narration ||
      values.narration.length < 3 ||
      !fileValue.value
    );
  }

  return !values.amount || !values.narration || values.narration.length < 3;
};

const handleGetQuote = async () => {
  const currency = values.currency || selectedBeneficiary.value?.currency;

  if (values.amount && selectedBeneficiary.value && selectedAsset.value) {
    const res = await getQuote({
      body: {
        asset_id: selectedAsset.value.id,
        amount_in_major: values.amount.toString(),
        counterparty_id: selectedBeneficiary.value.id,
        destination_currency: currency,
      },
    });
    quote.value = res;
  }

  if (!selectedBeneficiary.value) {
    toast.error("Please select a beneficiary", {
      position: "top-right",
    });
  }
};

const handleGetExhangeRate = async () => {
  const currency = values.currency || selectedBeneficiary.value?.currency;

  if (
    currency !== selectedAsset.value?.asset.currency &&
    selectedBeneficiary.value
  ) {
    const res = await getQuote({
      body: {
        asset_id: selectedAsset.value?.id,
        amount_in_major: "5000.00",
        counterparty_id: selectedBeneficiary.value.id,
        destination_currency: currency,
      },
    });
    exchangeRateQuote.value = res;
  }
};

const handleShowConfirmation = () => {
  if (!isRequiredFieldsEmpty()) {
    showTxnConfirmation.value = true;
  }
};

const handleSubmit = async () => {
  if (quote.value) {
    const payload = new FormData();
    payload.append("quote_id", quote.value.id);
    if (values.narration) {
      payload.append("description", values.narration);
    }
    if (fileValue.value) {
      payload.append("purpose_of_payment", fileValue.value);
    }

    try {
      loading.value = true;
      await withOtp({
        body: Object.fromEntries(payload),
        callback: async (otp?: string) => {
          const txn: CryptoTransactionResponse = await makePayment({
            body: payload,
            headers: otp ? { [LYNC_OTP_CODE_HEADER]: otp } : undefined,
          });
          loading.value = false;
          successfulTxn.value = getCryptoPaymentSuccessProps(txn);
          showTxnStatus.value = true;
          showTxnConfirmation.value = false;
          queryClient.invalidateQueries({ queryKey: [QueryKeys.ASSETS] });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOTAL_ASSETS_VALUE],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CRYPTO_TRANSACTIONS],
          });
          reset();
        },
      });
    } catch (err) {
      loading.value = false;
    }
  }
};

const handleCheckTransferEligibility = async () => {
  if (selectedBeneficiary.value && selectedAsset.value) {
    const res = await checkTransferEligibility({
      body: {
        counterparty_id: selectedBeneficiary.value.id,
        source_asset_id: selectedAsset.value.id,
      },
    });

    transferEligibility.value = res;

    if (res.supported) {
      handleGetExhangeRate();
    }
  }
};

watch(
  () => values.amount,
  debounce(() => {
    handleGetQuote();
    fieldErrors.amount = "";
  }, 1000),
);

watch(
  () => values.narration,
  (val) => {
    if (val.length > 3) {
      fieldErrors.narration = "";
    }
  },
);

watch(
  () => values.currency,
  () => {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    handleCheckTransferEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  },
);

watch(selectedBeneficiary, (val) => {
  if (val && selectedAsset.value) {
    quote.value = undefined;
    exchangeRateQuote.value = undefined;
    formFields.currency.value = val.currency;
    handleCheckTransferEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  }
});

watch(selectedAsset, () => {
  quote.value = undefined;
  exchangeRateQuote.value = undefined;
  if (selectedBeneficiary.value) {
    handleCheckTransferEligibility().then(() => {
      handleGetExhangeRate();
      handleGetQuote();
    });
  }
});

watch(assets, (val) => {
  if (!defaultAsset.value && val && val.items.length) {
    selectedAsset.value = val.items[0];
  } else if (defaultAsset.value && val && val.items.length) {
    selectedAsset.value = val.items.filter(
      (it) =>
        it.asset.currency === defaultAsset.value?.currency &&
        it.asset.blockchain === defaultAsset.value?.network,
    )[0];
  }
});

onMounted(() => {
  if (defaultAsset.value && assets.value && assets.value.items.length) {
    selectedAsset.value = assets.value.items.filter(
      (it) =>
        it.asset.currency === defaultAsset.value?.currency &&
        it.asset.blockchain === defaultAsset.value?.network,
    )[0];
  }
  if (defaultBeneficiary.value) {
    selectedBeneficiary.value = defaultBeneficiary.value as BeneficiaryResponse;
    formFields.currency.value = defaultBeneficiary.value.currency;
  }
});

onBeforeRouteLeave(() => {
  updateDefaultAsset(null);
  updateDefaultBeneficiary(null);
});
</script>
